import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/browser';
import currency from 'currency.js';
import { History } from 'history';
import React from 'react';
import { useAsync } from 'react-async';
import { FullsizePicture } from 'react-responsive-picture';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button } from 'reactstrap';

import ApiClient, { StripePlan } from '../api';
import { useUser } from '../context/user';
import slide1JPEG from '../images/bg-slide-1.jpg';
import slide1WEBP from '../images/bg-slide-1.webp';
import { MatchParams, User } from '../interfaces';
import { setDocumentTitle, STRIPE_PUBLIC_KEY } from '../utils';
import FullpageLoadingIndicator from './FullpageLoadingIndicator';

type PricingViewProps = RouteComponentProps<MatchParams>;

// NOTE: This is needed to appease TypeScript since Stripe is loaded externally.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Stripe: any;

async function getStripePlans(): Promise<Array<StripePlan>> {
    const response = await ApiClient.listPlans();
    return response.data;
}

async function startCheckout(user: User | null, plan: StripePlan, history: History): Promise<void> {
    if (user) {
        // TODO Handle errors from local API
        const response = await ApiClient.createStripeCheckoutSession({ plan: plan.id });
        const session = response.data;

        const stripe = Stripe(STRIPE_PUBLIC_KEY);
        const { error } = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        if (error) {
            // TODO Handle errors from Stripe API
        }
    } else {
        // TODO Update the registration page to redirect to Checkout
        history.push(`/register?plan=${plan.interval}`);
    }
}

const PricingView: React.FC<PricingViewProps> = (props: PricingViewProps) => {
    const { history } = props;
    const user = useUser();
    const { data, error, isLoading } = useAsync({ promiseFn: getStripePlans });

    setDocumentTitle('Pricing');

    if (isLoading) {
        return <FullpageLoadingIndicator />;
    }

    if (error) {
        Sentry.captureException(error);
        return <div>Failed to load subscription data.</div>;
    }

    const monthlyPlan = (data && data.find(plan => plan.interval === 'month')) as StripePlan;
    const yearlyPlan = (data && data.find(plan => plan.interval === 'year')) as StripePlan;
    const yearlyPlanMonthlyAmount = currency(yearlyPlan.amount).divide(12);

    return (
        <>
            <div className="headline-bg pricing-headline-bg">
                <FullsizePicture
                    sources={[
                        { srcSet: slide1WEBP, type: 'image/webp' },
                        { srcSet: `${slide1JPEG} 1400w`, type: 'image/png' },
                    ]}
                    alt="Friends laughing at table"
                />
            </div>
            <section className="pricing section section-on-bg">
                <div className="container">
                    <h2 className="title text-center">
                        14 day <span className="highlight">FREE</span> trial with all plans!
                    </h2>
                    <p className="intro text-center">
                        Pricing is simple and you can cancel or change your plan at any time.
                    </p>
                    <div className="price-cols m-auto">
                        <div className="items-wrapper row justify-content-center">
                            <div className="item price-1 col-md-4 col-12 text-center">
                                <div className="item-inner">
                                    <div className="heading">
                                        <h3 className="title">Associates</h3>
                                        <p className="price-figure">
                                            <span className="price-figure-inner">
                                                <span className="currency">$</span>
                                                <span className="number">
                                                    {currency(monthlyPlan.amount).format(false)}
                                                </span>
                                                <br />
                                                <span className="unit"> per month</span>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="content">
                                        <ul className="list-unstyled feature-list">
                                            <li>
                                                <FontAwesomeIcon icon="check" />
                                                Unlimited email notifications
                                            </li>
                                            <li>
                                                <FontAwesomeIcon icon="check" />
                                                Unlimited SMS notifications
                                            </li>
                                            <li className="disabled">
                                                <FontAwesomeIcon icon="times" />
                                                10% savings
                                            </li>
                                        </ul>
                                        <Button
                                            color="primary"
                                            className="btn-cta btn-cta-primary"
                                            onClick={(): Promise<void> => startCheckout(user, monthlyPlan, history)}
                                        >
                                            Start free trial
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="item price-2 col-md-4 col-12 text-center best-buy">
                                <div className="item-inner">
                                    <div className="heading">
                                        <h3 className="title">Best Friends</h3>
                                        <p className="price-figure">
                                            <span className="price-figure-inner">
                                                <span className="currency">$</span>
                                                <span className="number">{yearlyPlanMonthlyAmount.format(false)}</span>
                                                <br />
                                                <span className="unit">per month</span>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="content">
                                        <ul className="list-unstyled feature-list">
                                            <li>
                                                <FontAwesomeIcon icon="check" />
                                                Unlimited email notifications
                                            </li>
                                            <li>
                                                <FontAwesomeIcon icon="check" />
                                                Unlimited SMS notifications
                                            </li>
                                            <li>
                                                <FontAwesomeIcon icon="check" />
                                                Save over 10% by paying annually
                                            </li>
                                        </ul>
                                        <Button
                                            color="primary"
                                            className="btn-cta btn-cta-primary"
                                            onClick={(): Promise<void> => startCheckout(user, yearlyPlan, history)}
                                        >
                                            Start free trial
                                        </Button>
                                    </div>
                                    <div className="ribbon">
                                        <div className="text">Save 10%!</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withRouter(PricingView);
