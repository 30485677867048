import React from 'react';
import { Link } from 'react-router-dom';

import MiniPageHeader from './MiniPageHeader';

const CheckoutCancelledView: React.FC = () => {
    return (
        <div className="app">
            <MiniPageHeader />
            <div className="container">
                <div className="row">
                    <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">Checkout Cancelled</h2>
                            <p className="lead">Your checkout session was cancelled. You have not been charged.</p>
                            <Link to="/pricing" className="btn btn-block btn-cta-primary">
                                Try again
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutCancelledView;
