import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React, { MouseEvent } from 'react';
import { useAsync } from 'react-async';
import { withRouter } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';

import ApiClient, { Contact } from '../api';
import { setDocumentTitle } from '../utils';
import { getFullName } from '../utils/contacts';
import { parseDateTime } from '../utils/dates';
import FullpageLoadingIndicator from './FullpageLoadingIndicator';
import MiniPageHeader from './MiniPageHeader';

type Props = RouteComponentProps;

type InfoCellProps = {
    contact: Contact;
};

function stopClickPropagation(event: MouseEvent): void {
    event.stopPropagation();
}

const NameAndContactInfo: React.FC<InfoCellProps> = (props: InfoCellProps) => {
    const { contact } = props;
    return (
        <div>
            {getFullName(contact)}
            <br />
            {contact.phone_number ? (
                <a href={`tel:${contact.phone_number}`} onClick={stopClickPropagation}>
                    {contact.phone_number}
                    <br />
                </a>
            ) : null}
            {contact.email ? (
                <a href={`mailto:${contact.email}`} onClick={stopClickPropagation}>
                    {contact.email}
                    <br />
                </a>
            ) : null}
        </div>
    );
};

const ReminderInfo: React.FC<InfoCellProps> = (props: InfoCellProps) => {
    const { contact } = props;

    if (contact.reminder_frequency_in_days && contact.next_reminder_at) {
        const nextReminderAt = parseDateTime(contact.next_reminder_at);
        return (
            <div>
                <span>
                    Every {contact.reminder_frequency_in_days} days at {nextReminderAt.format('hh:mm a')}
                </span>
                <br />
                <span>Next reminder on {nextReminderAt.format('dddd, MMMM Do YYYY')}</span>
            </div>
        );
    } else {
        return <em className="text-warning">No reminder setup</em>;
    }
};

async function listContacts(): Promise<AxiosResponse<Array<Contact>>> {
    return ApiClient.listContacts();
}

const ContactListView: React.FC<Props> = (props: Props) => {
    const { history } = props;
    const { data } = useAsync({ promiseFn: listContacts });
    setDocumentTitle('Friends');

    function onClickRow(contact: Contact): () => void {
        return (): void => {
            history.push(`/friends/${contact.id}`);
        };
    }

    const render = (contacts: Contact[]): JSX.Element => {
        return (
            <>
                <MiniPageHeader />
                <div className="form-box">
                    <div className="form-box-inner">
                        <h1 className="title text-center mb-3">Friends</h1>
                        <div className="text-center">
                            {contacts.length > 0 && (
                                <div className="mb-3">
                                    <Link role="button" className="btn btn-cta-primary btn-sm mx-2" to="/friends/add">
                                        Add
                                    </Link>
                                    <Link role="button" className="btn btn-cta-primary btn-sm" to="/friends/add/google">
                                        Add from Google
                                    </Link>
                                </div>
                            )}
                        </div>
                        {contacts.length > 0 ? (
                            <table className="table table-striped table-hover contact-list">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Reminders</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts.map(
                                        (contact: Contact): JSX.Element => (
                                            <tr
                                                className="contact"
                                                key={contact.id}
                                                data-testid={contact.id}
                                                onClick={onClickRow(contact)}
                                            >
                                                <td className="align-middle">
                                                    <NameAndContactInfo contact={contact} />
                                                </td>
                                                <td className="align-middle">
                                                    <ReminderInfo contact={contact} />
                                                </td>
                                                <td className="align-middle">
                                                    <Link to={`/friends/${contact.id}`} onClick={stopClickPropagation}>
                                                        <FontAwesomeIcon icon="edit" className="d-none d-sm-block" />
                                                        <span className="d-sm-none">Edit</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <div className="jumbotron text-center">
                                <p className="lead">Click a button below to add a new friend and reminder.</p>
                                <Link role="button" className="btn btn-success btn-cta-primary mx-2" to="/friends/add">
                                    Add a friend
                                </Link>
                                <Link role="button" className="btn btn-cta-primary" to="/friends/add/google">
                                    Add friends from Google
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };
    return (
        <div className="app">
            <div className="container">{data ? render(data.data) : <FullpageLoadingIndicator />}</div>
        </div>
    );
};

export default withRouter(ContactListView);
