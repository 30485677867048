import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Collapse, NavbarToggler } from 'reactstrap';

import { useAuth } from '../context/auth';

type NavItemProps = {
    to: string;
} & React.HtmlHTMLAttributes<HTMLLIElement>;

const NavItem: React.FC<NavItemProps> = (props: NavItemProps) => {
    const { to } = props;
    const active = matchPath(window.location.pathname, { path: to });
    return (
        <li className={`nav-item ${active && 'active'}`} {...props}>
            <NavLink to={to} className="nav-link">
                {props.children}
            </NavLink>
        </li>
    );
};

const NavigationBar: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const { location } = props;
    const { user } = useAuth();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggle = (): void => setIsOpen(!isOpen);
    const [scrolled, setScrolled] = useState();

    useEffect(() => {
        const handleScroll = (): void => {
            if (window.pageYOffset > 1) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return (): void => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    if (!['/', '/pricing'].includes(location.pathname)) {
        return null;
    }

    return (
        <header id="header" className={`header navbar-fixed-top ${scrolled ? 'scrolled' : ''}`}>
            <div className="container">
                <h1 className="logo">
                    <Link to="/">
                        <span className="text">Friendly Reminder</span>
                    </Link>
                </h1>

                <nav className="main-nav navbar navbar-right navbar-inverse navbar-expand-md" role="navigation">
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <ul className="nav navbar-nav">
                            {user ? (
                                <li className="nav-item nav-item-cta last">
                                    <NavLink className="btn btn-cta btn-cta-secondary" to="/friends">
                                        Friends
                                    </NavLink>
                                </li>
                            ) : (
                                <>
                                    <NavItem to="/pricing">Pricing</NavItem>
                                    <NavItem to="/login">Login</NavItem>
                                    <li className="nav-item nav-item-cta last">
                                        <NavLink className="btn btn-cta btn-cta-secondary" to="/register">
                                            Sign Up Free
                                        </NavLink>
                                    </li>
                                </>
                            )}
                        </ul>
                    </Collapse>
                </nav>
            </div>
        </header>
    );
};

export default withRouter(NavigationBar);
