/* tslint:disable */
/* eslint-disable */
/**
 * Friendly Reminder
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof Contact
     */
    'reminder_frequency_in_days'?: number | null;
    /**
     * An empty value here indicates no reminder is set.
     * @type {string}
     * @memberof Contact
     */
    'next_reminder_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface EmailConfirmationHMAC
 */
export interface EmailConfirmationHMAC {
    /**
     * 
     * @type {string}
     * @memberof EmailConfirmationHMAC
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface FCMDevice
 */
export interface FCMDevice {
    /**
     * 
     * @type {number}
     * @memberof FCMDevice
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FCMDevice
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FCMDevice
     */
    'registration_id': string;
    /**
     * Unique device identifier
     * @type {string}
     * @memberof FCMDevice
     */
    'device_id'?: string | null;
    /**
     * Inactive devices will not be sent notifications
     * @type {boolean}
     * @memberof FCMDevice
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FCMDevice
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCMDevice
     */
    'type': FCMDeviceTypeEnum;
}

export const FCMDeviceTypeEnum = {
    Ios: 'ios',
    Android: 'android',
    Web: 'web'
} as const;

export type FCMDeviceTypeEnum = typeof FCMDeviceTypeEnum[keyof typeof FCMDeviceTypeEnum];

/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'expiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface PhoneNumberVerification
 */
export interface PhoneNumberVerification {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberVerification
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberVerification
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberVerification
     */
    'phone_number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneNumberVerification
     */
    'verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberVerification
     */
    'verified_at'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeCheckoutSession
 */
export interface StripeCheckoutSession {
    /**
     * 
     * @type {string}
     * @memberof StripeCheckoutSession
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeCheckoutSession
     */
    'plan': string;
}
/**
 * 
 * @export
 * @interface StripePlan
 */
export interface StripePlan {
    /**
     * 
     * @type {string}
     * @memberof StripePlan
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StripePlan
     */
    'amount': string;
    /**
     * Three-letter ISO currency code
     * @type {string}
     * @memberof StripePlan
     */
    'currency': string;
    /**
     * The frequency with which a subscription should be billed.
     * @type {string}
     * @memberof StripePlan
     */
    'interval': StripePlanIntervalEnum;
    /**
     * The number of intervals (specified in the interval property) between each subscription billing.
     * @type {number}
     * @memberof StripePlan
     */
    'interval_count'?: number | null;
    /**
     * A brief description of the plan, hidden from customers.
     * @type {string}
     * @memberof StripePlan
     */
    'nickname'?: string;
    /**
     * Number of trial period days granted when subscribing a customer to this plan. Null if the plan has no trial period.
     * @type {number}
     * @memberof StripePlan
     */
    'trial_period_days'?: number | null;
}

export const StripePlanIntervalEnum = {
    Day: 'day',
    Month: 'month',
    Week: 'week',
    Year: 'year'
} as const;

export type StripePlanIntervalEnum = typeof StripePlanIntervalEnum[keyof typeof StripePlanIntervalEnum];

/**
 * 
 * @export
 * @interface StripePortalSession
 */
export interface StripePortalSession {
    /**
     * 
     * @type {string}
     * @memberof StripePortalSession
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripePortalSession
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface StripeSubscription
 */
export interface StripeSubscription {
    /**
     * 
     * @type {string}
     * @memberof StripeSubscription
     */
    'id': string;
    /**
     * Determines the date of the first full invoice, and, for plans with `month` or `year` intervals, the day of the month for subsequent invoices.
     * @type {string}
     * @memberof StripeSubscription
     */
    'billing_cycle_anchor'?: string | null;
    /**
     * End of the current period for which the subscription has been invoiced. At the end of this period, a new invoice will be created.
     * @type {string}
     * @memberof StripeSubscription
     */
    'current_period_end': string;
    /**
     * Start of the current period for which the subscription has been invoiced.
     * @type {string}
     * @memberof StripeSubscription
     */
    'current_period_start': string;
    /**
     * 
     * @type {UserSubscriptionPlan}
     * @memberof StripeSubscription
     */
    'plan': UserSubscriptionPlan;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscription
     */
    'start'?: string;
    /**
     * The status of this subscription.
     * @type {string}
     * @memberof StripeSubscription
     */
    'status': StripeSubscriptionStatusEnum;
    /**
     * If the subscription has a trial, the beginning of that trial.
     * @type {string}
     * @memberof StripeSubscription
     */
    'trial_start'?: string | null;
    /**
     * If the subscription has a trial, the end of that trial.
     * @type {string}
     * @memberof StripeSubscription
     */
    'trial_end'?: string | null;
}

export const StripeSubscriptionStatusEnum = {
    Active: 'active',
    Canceled: 'canceled',
    Incomplete: 'incomplete',
    IncompleteExpired: 'incomplete_expired',
    PastDue: 'past_due',
    Trialing: 'trialing',
    Unpaid: 'unpaid'
} as const;

export type StripeSubscriptionStatusEnum = typeof StripeSubscriptionStatusEnum[keyof typeof StripeSubscriptionStatusEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone_number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_email_verified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_phone_number_verified'?: boolean;
    /**
     * 
     * @type {UserSubscription}
     * @memberof User
     */
    'subscription'?: UserSubscription;
}
/**
 * 
 * @export
 * @interface UserSubscription
 */
export interface UserSubscription {
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'id': string;
    /**
     * Determines the date of the first full invoice, and, for plans with `month` or `year` intervals, the day of the month for subsequent invoices.
     * @type {string}
     * @memberof UserSubscription
     */
    'billing_cycle_anchor'?: string | null;
    /**
     * End of the current period for which the subscription has been invoiced. At the end of this period, a new invoice will be created.
     * @type {string}
     * @memberof UserSubscription
     */
    'current_period_end': string;
    /**
     * Start of the current period for which the subscription has been invoiced.
     * @type {string}
     * @memberof UserSubscription
     */
    'current_period_start': string;
    /**
     * 
     * @type {UserSubscriptionPlan}
     * @memberof UserSubscription
     */
    'plan': UserSubscriptionPlan;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'start'?: string;
    /**
     * The status of this subscription.
     * @type {string}
     * @memberof UserSubscription
     */
    'status': UserSubscriptionStatusEnum;
    /**
     * If the subscription has a trial, the beginning of that trial.
     * @type {string}
     * @memberof UserSubscription
     */
    'trial_start'?: string | null;
    /**
     * If the subscription has a trial, the end of that trial.
     * @type {string}
     * @memberof UserSubscription
     */
    'trial_end'?: string | null;
}

export const UserSubscriptionStatusEnum = {
    Active: 'active',
    Canceled: 'canceled',
    Incomplete: 'incomplete',
    IncompleteExpired: 'incomplete_expired',
    PastDue: 'past_due',
    Trialing: 'trialing',
    Unpaid: 'unpaid'
} as const;

export type UserSubscriptionStatusEnum = typeof UserSubscriptionStatusEnum[keyof typeof UserSubscriptionStatusEnum];

/**
 * 
 * @export
 * @interface UserSubscriptionPlan
 */
export interface UserSubscriptionPlan {
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    'amount': string;
    /**
     * Three-letter ISO currency code
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    'currency': string;
    /**
     * The frequency with which a subscription should be billed.
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    'interval': UserSubscriptionPlanIntervalEnum;
    /**
     * The number of intervals (specified in the interval property) between each subscription billing.
     * @type {number}
     * @memberof UserSubscriptionPlan
     */
    'interval_count'?: number | null;
    /**
     * A brief description of the plan, hidden from customers.
     * @type {string}
     * @memberof UserSubscriptionPlan
     */
    'nickname'?: string;
    /**
     * Number of trial period days granted when subscribing a customer to this plan. Null if the plan has no trial period.
     * @type {number}
     * @memberof UserSubscriptionPlan
     */
    'trial_period_days'?: number | null;
}

export const UserSubscriptionPlanIntervalEnum = {
    Day: 'day',
    Month: 'month',
    Week: 'week',
    Year: 'year'
} as const;

export type UserSubscriptionPlanIntervalEnum = typeof UserSubscriptionPlanIntervalEnum[keyof typeof UserSubscriptionPlanIntervalEnum];


/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Contacts to remind users to communicate with.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (contact?: Contact, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPhoneNumberVerification: async (phoneNumberVerification?: PhoneNumberVerification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/phone_number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneNumberVerification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StripeCheckoutSession} [stripeCheckoutSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeCheckoutSession: async (stripeCheckoutSession?: StripeCheckoutSession, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe/checkout/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeCheckoutSession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StripePortalSession} [stripePortalSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripePortalSession: async (stripePortalSession?: StripePortalSession, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe/portal/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripePortalSession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Log the user out of all sessions I.E. deletes all auth tokens for the user
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAllTokens: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/token/logout_all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyContact: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyContact', 'id', id)
            const localVarPath = `/api/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyFCMDevice: async (registrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('destroyFCMDevice', 'registrationId', registrationId)
            const localVarPath = `/api/v1/me/devices/{registration_id}`
                .replace(`{${"registration_id"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyToken: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/token/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (login?: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/token/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFCMDevices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateContact: async (id: string, contact?: Contact, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateContact', 'id', id)
            const localVarPath = `/api/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {EmailConfirmationHMAC} [emailConfirmationHMAC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateEmailConfirmationHMAC: async (key: string, emailConfirmationHMAC?: EmailConfirmationHMAC, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('partialUpdateEmailConfirmationHMAC', 'key', key)
            const localVarPath = `/api/v1/email-hmac/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailConfirmationHMAC, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateFCMDevice: async (registrationId: string, fCMDevice?: FCMDevice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('partialUpdateFCMDevice', 'registrationId', registrationId)
            const localVarPath = `/api/v1/me/devices/{registration_id}`
                .replace(`{${"registration_id"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fCMDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdatePhoneNumberVerification: async (phoneNumberVerification?: PhoneNumberVerification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/phone_number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneNumberVerification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateUser: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDevice: async (fCMDevice?: FCMDevice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fCMDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveContact: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveContact', 'id', id)
            const localVarPath = `/api/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEmailConfirmationHMAC: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('retrieveEmailConfirmationHMAC', 'key', key)
            const localVarPath = `/api/v1/email-hmac/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFCMDevice: async (registrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('retrieveFCMDevice', 'registrationId', registrationId)
            const localVarPath = `/api/v1/me/devices/{registration_id}`
                .replace(`{${"registration_id"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify a phone number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePhoneNumberVerification: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/phone_number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Manage Stripe subscriptions.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStripeSubscription: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveStripeSubscription', 'id', id)
            const localVarPath = `/api/v1/stripe/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact: async (id: string, contact?: Contact, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContact', 'id', id)
            const localVarPath = `/api/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {EmailConfirmationHMAC} [emailConfirmationHMAC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailConfirmationHMAC: async (key: string, emailConfirmationHMAC?: EmailConfirmationHMAC, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateEmailConfirmationHMAC', 'key', key)
            const localVarPath = `/api/v1/email-hmac/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailConfirmationHMAC, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFCMDevice: async (registrationId: string, fCMDevice?: FCMDevice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('updateFCMDevice', 'registrationId', registrationId)
            const localVarPath = `/api/v1/me/devices/{registration_id}`
                .replace(`{${"registration_id"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fCMDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoneNumberVerification: async (phoneNumberVerification?: PhoneNumberVerification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/phone_number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneNumberVerification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * Contacts to remind users to communicate with.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(contact?: Contact, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneNumberVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPhoneNumberVerification(phoneNumberVerification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StripeCheckoutSession} [stripeCheckoutSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStripeCheckoutSession(stripeCheckoutSession?: StripeCheckoutSession, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeCheckoutSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStripeCheckoutSession(stripeCheckoutSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StripePortalSession} [stripePortalSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStripePortalSession(stripePortalSession?: StripePortalSession, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripePortalSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStripePortalSession(stripePortalSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Log the user out of all sessions I.E. deletes all auth tokens for the user
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyAllTokens(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyAllTokens(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyContact(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyContact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyFCMDevice(registrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyFCMDevice(registrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyToken(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(login?: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContacts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contact>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContacts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFCMDevices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FCMDevice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFCMDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlans(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StripePlan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateContact(id: string, contact?: Contact, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateContact(id, contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {EmailConfirmationHMAC} [emailConfirmationHMAC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateEmailConfirmationHMAC(key: string, emailConfirmationHMAC?: EmailConfirmationHMAC, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfirmationHMAC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateEmailConfirmationHMAC(key, emailConfirmationHMAC, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateFCMDevice(registrationId: string, fCMDevice?: FCMDevice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FCMDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateFCMDevice(registrationId, fCMDevice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdatePhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneNumberVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdatePhoneNumberVerification(phoneNumberVerification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateUser(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDevice(fCMDevice?: FCMDevice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FCMDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerDevice(fCMDevice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveContact(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveContact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveEmailConfirmationHMAC(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfirmationHMAC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveEmailConfirmationHMAC(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveFCMDevice(registrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FCMDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveFCMDevice(registrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verify a phone number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePhoneNumberVerification(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneNumberVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePhoneNumberVerification(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Manage Stripe subscriptions.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveStripeSubscription(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveStripeSubscription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContact(id: string, contact?: Contact, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContact(id, contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {EmailConfirmationHMAC} [emailConfirmationHMAC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailConfirmationHMAC(key: string, emailConfirmationHMAC?: EmailConfirmationHMAC, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfirmationHMAC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailConfirmationHMAC(key, emailConfirmationHMAC, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFCMDevice(registrationId: string, fCMDevice?: FCMDevice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FCMDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFCMDevice(registrationId, fCMDevice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneNumberVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePhoneNumberVerification(phoneNumberVerification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * Contacts to remind users to communicate with.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(contact?: Contact, options?: any): AxiosPromise<Contact> {
            return localVarFp.createContact(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: any): AxiosPromise<PhoneNumberVerification> {
            return localVarFp.createPhoneNumberVerification(phoneNumberVerification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StripeCheckoutSession} [stripeCheckoutSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeCheckoutSession(stripeCheckoutSession?: StripeCheckoutSession, options?: any): AxiosPromise<StripeCheckoutSession> {
            return localVarFp.createStripeCheckoutSession(stripeCheckoutSession, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StripePortalSession} [stripePortalSession] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripePortalSession(stripePortalSession?: StripePortalSession, options?: any): AxiosPromise<StripePortalSession> {
            return localVarFp.createStripePortalSession(stripePortalSession, options).then((request) => request(axios, basePath));
        },
        /**
         * Log the user out of all sessions I.E. deletes all auth tokens for the user
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAllTokens(body?: any, options?: any): AxiosPromise<void> {
            return localVarFp.destroyAllTokens(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyContact(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyFCMDevice(registrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyFCMDevice(registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyToken(body?: any, options?: any): AxiosPromise<void> {
            return localVarFp.destroyToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(login?: Login, options?: any): AxiosPromise<Login> {
            return localVarFp.getToken(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts(options?: any): AxiosPromise<Array<Contact>> {
            return localVarFp.listContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFCMDevices(options?: any): AxiosPromise<Array<FCMDevice>> {
            return localVarFp.listFCMDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlans(options?: any): AxiosPromise<Array<StripePlan>> {
            return localVarFp.listPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateContact(id: string, contact?: Contact, options?: any): AxiosPromise<Contact> {
            return localVarFp.partialUpdateContact(id, contact, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {EmailConfirmationHMAC} [emailConfirmationHMAC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateEmailConfirmationHMAC(key: string, emailConfirmationHMAC?: EmailConfirmationHMAC, options?: any): AxiosPromise<EmailConfirmationHMAC> {
            return localVarFp.partialUpdateEmailConfirmationHMAC(key, emailConfirmationHMAC, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateFCMDevice(registrationId: string, fCMDevice?: FCMDevice, options?: any): AxiosPromise<FCMDevice> {
            return localVarFp.partialUpdateFCMDevice(registrationId, fCMDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdatePhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: any): AxiosPromise<PhoneNumberVerification> {
            return localVarFp.partialUpdatePhoneNumberVerification(phoneNumberVerification, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateUser(user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.partialUpdateUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDevice(fCMDevice?: FCMDevice, options?: any): AxiosPromise<FCMDevice> {
            return localVarFp.registerDevice(fCMDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveContact(id: string, options?: any): AxiosPromise<Contact> {
            return localVarFp.retrieveContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEmailConfirmationHMAC(key: string, options?: any): AxiosPromise<EmailConfirmationHMAC> {
            return localVarFp.retrieveEmailConfirmationHMAC(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFCMDevice(registrationId: string, options?: any): AxiosPromise<FCMDevice> {
            return localVarFp.retrieveFCMDevice(registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify a phone number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePhoneNumberVerification(options?: any): AxiosPromise<PhoneNumberVerification> {
            return localVarFp.retrievePhoneNumberVerification(options).then((request) => request(axios, basePath));
        },
        /**
         * Manage Stripe subscriptions.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStripeSubscription(id: string, options?: any): AxiosPromise<StripeSubscription> {
            return localVarFp.retrieveStripeSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUser(options?: any): AxiosPromise<User> {
            return localVarFp.retrieveUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Contacts to remind users to communicate with.
         * @param {string} id A UUID string identifying this contact.
         * @param {Contact} [contact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact(id: string, contact?: Contact, options?: any): AxiosPromise<Contact> {
            return localVarFp.updateContact(id, contact, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve and update Email HMAC confirmations.
         * @param {string} key 
         * @param {EmailConfirmationHMAC} [emailConfirmationHMAC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailConfirmationHMAC(key: string, emailConfirmationHMAC?: EmailConfirmationHMAC, options?: any): AxiosPromise<EmailConfirmationHMAC> {
            return localVarFp.updateEmailConfirmationHMAC(key, emailConfirmationHMAC, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {FCMDevice} [fCMDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFCMDevice(registrationId: string, fCMDevice?: FCMDevice, options?: any): AxiosPromise<FCMDevice> {
            return localVarFp.updateFCMDevice(registrationId, fCMDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify a phone number.
         * @param {PhoneNumberVerification} [phoneNumberVerification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: any): AxiosPromise<PhoneNumberVerification> {
            return localVarFp.updatePhoneNumberVerification(phoneNumberVerification, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve and update details about the authenticated user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * Contacts to remind users to communicate with.
     * @param {Contact} [contact] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createContact(contact?: Contact, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createContact(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify a phone number.
     * @param {PhoneNumberVerification} [phoneNumberVerification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createPhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createPhoneNumberVerification(phoneNumberVerification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StripeCheckoutSession} [stripeCheckoutSession] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createStripeCheckoutSession(stripeCheckoutSession?: StripeCheckoutSession, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createStripeCheckoutSession(stripeCheckoutSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StripePortalSession} [stripePortalSession] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createStripePortalSession(stripePortalSession?: StripePortalSession, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createStripePortalSession(stripePortalSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Log the user out of all sessions I.E. deletes all auth tokens for the user
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyAllTokens(body?: any, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyAllTokens(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contacts to remind users to communicate with.
     * @param {string} id A UUID string identifying this contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyContact(id: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyFCMDevice(registrationId: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyFCMDevice(registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyToken(body?: any, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Login} [login] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getToken(login?: Login, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getToken(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contacts to remind users to communicate with.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listContacts(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listFCMDevices(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listFCMDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listPlans(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contacts to remind users to communicate with.
     * @param {string} id A UUID string identifying this contact.
     * @param {Contact} [contact] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateContact(id: string, contact?: Contact, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateContact(id, contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve and update Email HMAC confirmations.
     * @param {string} key 
     * @param {EmailConfirmationHMAC} [emailConfirmationHMAC] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateEmailConfirmationHMAC(key: string, emailConfirmationHMAC?: EmailConfirmationHMAC, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateEmailConfirmationHMAC(key, emailConfirmationHMAC, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationId 
     * @param {FCMDevice} [fCMDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateFCMDevice(registrationId: string, fCMDevice?: FCMDevice, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateFCMDevice(registrationId, fCMDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify a phone number.
     * @param {PhoneNumberVerification} [phoneNumberVerification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdatePhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdatePhoneNumberVerification(phoneNumberVerification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve and update details about the authenticated user.
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateUser(user?: User, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FCMDevice} [fCMDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public registerDevice(fCMDevice?: FCMDevice, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).registerDevice(fCMDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contacts to remind users to communicate with.
     * @param {string} id A UUID string identifying this contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveContact(id: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve and update Email HMAC confirmations.
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveEmailConfirmationHMAC(key: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveEmailConfirmationHMAC(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveFCMDevice(registrationId: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveFCMDevice(registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify a phone number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrievePhoneNumberVerification(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrievePhoneNumberVerification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Manage Stripe subscriptions.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveStripeSubscription(id: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveStripeSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve and update details about the authenticated user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveUser(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contacts to remind users to communicate with.
     * @param {string} id A UUID string identifying this contact.
     * @param {Contact} [contact] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updateContact(id: string, contact?: Contact, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updateContact(id, contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve and update Email HMAC confirmations.
     * @param {string} key 
     * @param {EmailConfirmationHMAC} [emailConfirmationHMAC] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updateEmailConfirmationHMAC(key: string, emailConfirmationHMAC?: EmailConfirmationHMAC, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updateEmailConfirmationHMAC(key, emailConfirmationHMAC, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationId 
     * @param {FCMDevice} [fCMDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updateFCMDevice(registrationId: string, fCMDevice?: FCMDevice, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updateFCMDevice(registrationId, fCMDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify a phone number.
     * @param {PhoneNumberVerification} [phoneNumberVerification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updatePhoneNumberVerification(phoneNumberVerification?: PhoneNumberVerification, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updatePhoneNumberVerification(phoneNumberVerification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve and update details about the authenticated user.
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updateUser(user?: User, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updateUser(user, options).then((request) => request(this.axios, this.basePath));
    }
}


