import React from 'react';
import { Link } from 'react-router-dom';

import LogoutFooter from './LogoutFooter';

const AppFooter: React.FC = () => {
    const creationYear = 2019;
    const currentYear = new Date().getFullYear();

    // TODO Make this sticky again!
    return (
        <footer className="footer mt-auto py-3 px-3">
            <div className="row">
                <div className="col-12 col-md-6">
                    <LogoutFooter />
                </div>
                <div className="col-12 col-sm-6 text-md-right">
                    <Link to="/privacy">Privacy Policy</Link> &bull; Copyright &copy; {creationYear}
                    {currentYear === creationYear ? ' ' : `-${currentYear} `}
                    <a href="https://dev.clintonblackburn.com" target="_blank" rel="noopener noreferrer">
                        Clinton Blackburn
                    </a>
                    . All right reserved.
                </div>
            </div>
        </footer>
    );
};

export default AppFooter;
