import * as Sentry from '@sentry/browser';
import React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import ApiClient, { Contact } from '../api';
import { SetSubmitting } from '../interfaces';
import { setDocumentTitle } from '../utils';
import { reportEvent } from '../utils/analytics';
import ContactForm from './ContactForm';
import MiniPageHeader from './MiniPageHeader';

const ContactCreateView: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    setDocumentTitle('Add a friend');

    async function onSubmit(values: Contact, setSubmitting: SetSubmitting): Promise<void> {
        setSubmitting(true);
        try {
            const response = await ApiClient.createContact(values);
            const contact = response.data;

            setSubmitting(false);

            reportEvent({
                category: 'Friends',
                action: 'Friend created',
            });

            props.history.push(`/friends/${contact.id}`);
        } catch (error) {
            // TODO Display an alert
            Sentry.captureException(error);
        }
    }

    return (
        <div className="app">
            <MiniPageHeader />
            <div className="container">
                <div className="row">
                    <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">Add Friend</h2>
                            <ContactForm initialValue={{}} onSubmit={onSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ContactCreateView);
