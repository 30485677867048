import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ApiClient, { Contact } from '../api';
import { reportEvent } from '../utils/analytics';

type Props = RouteComponentProps & {
    contact: Contact;
};

const ContactDeleteButton: React.FC<Props> = (props: Props) => {
    const [open, setOpen] = useState(false);

    function toggle(): void {
        setOpen(!open);
    }

    const { contact, history } = props;

    function onRemove(): void {
        if (!contact.id) {
            Sentry.captureException('Attempted to remove contact with no ID');
            return;
        }

        ApiClient.destroyContact(contact.id)
            .then((): void => {
                reportEvent({
                    category: 'Friends',
                    action: 'Friend deleted',
                });

                toggle();
                history.push('/friends');
            })
            .catch((error): void => {
                // TODO Display an alert
                Sentry.captureException(error);
            });
    }

    return (
        <Button color="link" onClick={toggle}>
            Remove
            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
                <ModalBody>Clicking [Remove] will remove {contact.first_name} from your friends list.</ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={onRemove}>
                        Remove
                    </Button>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Button>
    );
};

export default withRouter(ContactDeleteButton);
