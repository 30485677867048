import React from 'react';
import { Spinner } from 'reactstrap';

const FullpageLoadingIndicator: React.FC = () => {
    return (
        <div
            className="row h-100 justify-content-center align-items-center fullpage-loading-indicator"
            style={{ minHeight: '400px' }}
        >
            <Spinner type="grow" />
        </div>
    );
};

export default FullpageLoadingIndicator;
