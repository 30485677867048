import React from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AppChrome from './AppChrome';
import CheckoutCancelledView from './components/CheckoutCancelledView';
import CheckoutSuccessView from './components/CheckoutSuccessView';
import ConfirmEmailView from './components/ConfirmEmailView';
import ContactCreateView from './components/ContactCreateView';
import ContactDetailView from './components/ContactDetailView';
import ContactListView from './components/ContactListView';
import GoogleImportView from './components/GoogleImportView';
import LandingPageView from './components/LandingPageView';
import LogoutView from './components/LogoutView';
import PricingView from './components/PricingView';
import PrivacyPageView from './components/PrivacyPageView';
import UserEditView from './components/UserEditView';
import VerifyPhoneNumberView from './components/VerifyPhoneNumberView';
import { MatchParams } from './interfaces';
import { RouteWithTracker } from './utils/analytics';

const Friends: React.FC<RouteComponentProps<MatchParams>> = ({ match }: RouteComponentProps<MatchParams>) => {
    return (
        <Switch>
            <RouteWithTracker path={`${match.path}/add/google`} component={GoogleImportView} />
            <RouteWithTracker path={`${match.path}/add`} component={ContactCreateView} />
            <RouteWithTracker path={`${match.path}/:id`} component={ContactDetailView} />
            <RouteWithTracker path="" component={ContactListView} />
        </Switch>
    );
};

const AuthenticatedApp: React.FC = () => {
    return (
        <Router>
            <AppChrome>
                <Switch>
                    <RouteWithTracker exact path="/" component={LandingPageView} />
                    <RouteWithTracker path="/confirm-email/:key" component={ConfirmEmailView} />
                    <RouteWithTracker path="/friends" component={Friends} />
                    <RouteWithTracker exact path="/logout" component={LogoutView} />
                    <RouteWithTracker exact path="/me" component={UserEditView} />
                    <RouteWithTracker exact path="/pricing" component={PricingView} />
                    <RouteWithTracker exact path="/privacy" component={PrivacyPageView} />
                    <RouteWithTracker exact path="/purchase/cancel" component={CheckoutCancelledView} />
                    <RouteWithTracker exact path="/purchase/success" component={CheckoutSuccessView} />
                    <RouteWithTracker path="/verify-phone" component={VerifyPhoneNumberView} />
                    <Route path="*">
                        <Redirect to="/friends" />
                    </Route>
                </Switch>
            </AppChrome>
        </Router>
    );
};

export default AuthenticatedApp;
