import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';

import ApiClient, { EmailConfirmationHMAC } from '../api';
import { setDocumentTitle } from '../utils';
import { reportEvent } from '../utils/analytics';
import FullpageLoadingIndicator from './FullpageLoadingIndicator';
import MiniPageHeader from './MiniPageHeader';

type MatchParams = {
    key: string;
};
type FormErrors = {
    nonField: string;
};
type ConfirmEmailViewProps = RouteComponentProps<MatchParams>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function getConfirmationData({ key }: any): Promise<EmailConfirmationHMAC> {
    const response = await ApiClient.retrieveEmailConfirmationHMAC(key);
    return response.data;
}

const ConfirmEmailView: React.FC<ConfirmEmailViewProps> = (props: ConfirmEmailViewProps) => {
    const key = props.match.params.key;
    const { data, error, isLoading, reload } = useAsync({ promiseFn: getConfirmationData, key: key });
    const [succeeded, setSucceeded] = useState<boolean>(false);
    setDocumentTitle('Confirm Email');

    function onSubmit(values: FormikValues, helpers: FormikHelpers<FormErrors>): void {
        ApiClient.updateEmailConfirmationHMAC(key)
            .then((): void => {
                helpers.setSubmitting(false);
                helpers.setFieldError('nonField', '');
                reportEvent({
                    category: 'User',
                    action: 'Email verified',
                });
                setSucceeded(true);
            })
            .catch((error): void => {
                helpers.setSubmitting(false);
                const form = error && error.response && error.response.data && error.response.data.form;
                const errors = form && form.errors;

                const errorMsg = errors ? errors[0] : 'An error occurred. Please try again.';
                helpers.setFieldError('nonField', errorMsg);
                setSucceeded(false);
            });
    }

    let innerContent: JSX.Element;

    if (isLoading) {
        innerContent = <FullpageLoadingIndicator />;
    } else if (error) {
        innerContent = (
            <>
                <p className="lead">
                    An error occurred while determining your email verification status. Reload the page to try again.
                </p>
                <Button block color="cta-primary" onClick={reload}>
                    Check again
                </Button>
            </>
        );
    } else {
        const { email } = data as EmailConfirmationHMAC;
        innerContent = (
            <>
                <Formik initialValues={{ nonField: '' }} onSubmit={onSubmit}>
                    {({ errors, isSubmitting, isValid }): JSX.Element => (
                        <>
                            {errors && errors.nonField && (
                                <Alert data-testid="errors" color="danger">
                                    {errors.nonField}
                                </Alert>
                            )}

                            {succeeded && (
                                <Alert data-testid="successAlert" color="success">
                                    Your email address has been confirmed, and email alerts enabled.{' '}
                                    <Link to="/friends">Add some friends!</Link>
                                </Alert>
                            )}
                            <Form>
                                <p className="lead">
                                    Please confirm you wish the email address <em>{email}</em> to be associated with
                                    your account.
                                </p>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting || !isValid}
                                    aria-disabled={isSubmitting || !isValid}
                                    data-testid="submit"
                                >
                                    Confirm
                                </Button>
                            </Form>
                        </>
                    )}
                </Formik>
            </>
        );
    }

    return (
        <div className="app">
            <MiniPageHeader />
            <div className="container">
                <div className="row">
                    <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">Email Verification</h2>
                            {innerContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmEmailView;
