import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';

import ApiClient, { PhoneNumberVerification } from '../api';
import { useAuth } from '../context/auth';
import { setDocumentTitle } from '../utils';
import { reportEvent } from '../utils/analytics';
import FullpageLoadingIndicator from './FullpageLoadingIndicator';
import MiniPageHeader from './MiniPageHeader';

type FormErrors = {
    code: string;
    nonField: string;
};
type NewCodeRequestStatus = 'failed' | 'pending' | 'succeeded';

async function getVerificationData(): Promise<PhoneNumberVerification> {
    const response = await ApiClient.retrievePhoneNumberVerification();
    return response.data;
}

const VerifyPhoneNumberView: React.FC = () => {
    const { refreshUserData } = useAuth();
    const { data, error, isLoading, reload } = useAsync({ promiseFn: getVerificationData });
    const [verificationSucceeded, setVerificationSucceeded] = useState<boolean>(false);
    const [newCodeRequested, setNewCodeRequested] = useState<NewCodeRequestStatus>('pending');

    setDocumentTitle('Phone Verification');

    if (isLoading) {
        return <FullpageLoadingIndicator />;
    }

    function resendVerificationCode(): void {
        ApiClient.createPhoneNumberVerification()
            .then((): void => {
                setNewCodeRequested('succeeded');
                reportEvent({
                    category: 'User',
                    action: 'Phone verified',
                });
                reload();
            })
            .catch((): void => {
                setNewCodeRequested('failed');
                reload();
            });
    }

    function onSubmit(values: FormikValues, helpers: FormikHelpers<FormErrors>): void {
        setNewCodeRequested('pending');
        ApiClient.updatePhoneNumberVerification({ code: values.code })
            .then((): void => {
                helpers.setSubmitting(false);
                helpers.setFieldError('nonField', '');
                setVerificationSucceeded(true);
                refreshUserData();
            })
            .catch((error): void => {
                helpers.setSubmitting(false);
                const form = error && error.response && error.response.data && error.response.data.form;
                const errors = form && form.errors;

                const errorMsg = errors ? errors[0] : 'An error occurred. Please try again.';
                helpers.setFieldError('nonField', errorMsg);
                setVerificationSucceeded(false);
            });
    }

    let innerContent: JSX.Element;

    if (error) {
        innerContent = (
            <>
                <p className="lead">
                    An error occurred while determining your phone verification status. Reload the page to try again.
                </p>
                <Button block color="cta-primary" onClick={reload}>
                    Check again
                </Button>
            </>
        );
    } else {
        const { phone_number: phoneNumber, verified } = data as PhoneNumberVerification;
        if (verified) {
            innerContent = (
                <>
                    <p className="lead">
                        Your phone number, <em>{phoneNumber}</em>, is verified.
                    </p>
                    <Link role="button" className="btn btn-cta-primary btn-block" to="/friends">
                        Back to friends!
                    </Link>
                </>
            );
        } else {
            innerContent = (
                <>
                    <Formik initialValues={{ code: '', nonField: '' }} onSubmit={onSubmit}>
                        {({ errors, isSubmitting, isValid }): JSX.Element => (
                            <>
                                {errors && errors.nonField && (
                                    <Alert data-testid="errors" color="danger">
                                        {errors.nonField}
                                    </Alert>
                                )}
                                {newCodeRequested === 'succeeded' && (
                                    <Alert color="success">
                                        A new verification code was sent to <em>{phoneNumber}</em>.
                                    </Alert>
                                )}

                                {verificationSucceeded && (
                                    <Alert data-testid="successAlert" color="success">
                                        Your phone number has been verified, and SMS alerts enabled.{' '}
                                        <Link to="/friends">Back to friends!</Link>
                                    </Alert>
                                )}
                                <Form>
                                    <p className="lead">
                                        Please confirm you wish the phone number <em>{phoneNumber}</em> to be associated
                                        with your account.
                                    </p>
                                    <div className="form-group">
                                        <FontAwesomeIcon icon="user-check" />
                                        <label htmlFor="code" className="sr-only">
                                            Verification Code
                                        </label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="code"
                                            name="code"
                                            placeholder="Verification code"
                                            autoFocus
                                            required
                                        />
                                    </div>
                                    <Button
                                        type="submit"
                                        block
                                        color="cta-primary"
                                        disabled={isSubmitting || !isValid}
                                        aria-disabled={isSubmitting || !isValid}
                                        data-testid="submit"
                                    >
                                        Confirm
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                    <div className="form-footer">
                        <p className="lead">
                            Need a new verification code?{' '}
                            <Button color="link" onClick={resendVerificationCode}>
                                Get a new one.
                            </Button>
                        </p>
                    </div>
                </>
            );
        }
    }

    return (
        <div className="app">
            <MiniPageHeader />
            <div className="container">
                <div className="row">
                    <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">Phone Verification</h2>
                            {innerContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyPhoneNumberView;
