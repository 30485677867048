import React from 'react';

import { User } from '../interfaces';
import { useAuth } from './auth';

const UserContext = React.createContext<User | null>(null);

function UserProvider(props: any): React.ReactElement {
    const { user } = useAuth();
    return <UserContext.Provider value={user} {...props} />;
}

function useUser(): User | null {
    const context = React.useContext(UserContext);

    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error(`useUser must be used within a UserProvider`);
    }

    return context;
}

export { UserProvider, useUser };
