import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';

import { useAuth } from '../context/auth';
import { LoginFormData } from '../interfaces';
import { setDocumentTitle } from '../utils';
import MiniPageHeader from './MiniPageHeader';

type FormErrors = LoginFormData & { nonField: string };

const LoginView: React.FC = () => {
    const { login } = useAuth();
    setDocumentTitle('Login');

    function onSubmit(values: FormikValues, helpers: FormikHelpers<FormErrors>): void {
        const formData: LoginFormData = {
            email: values.email,
            password: values.password,
            remember: values.remember,
        };

        login(formData)
            .then((): void => {
                helpers.setSubmitting(false);
                helpers.setFieldError('nonField', '');
            })
            .catch((error): void => {
                helpers.setSubmitting(false);
                const errors =
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.form &&
                    error.response.data.form.errors;

                const errorMsg = errors ? errors[0] : 'The email and password entered are incorrect.';
                helpers.setFieldError('nonField', errorMsg);
            });
    }

    function validate(values: FormikValues): FormikErrors<FormikValues> {
        const errors: FormikErrors<FormikValues> = {};

        if (!values.email) {
            errors.email = 'Required';
        }

        if (!values.password) {
            errors.password = 'Required';
        }

        return errors;
    }

    const initialValues = { email: '', password: '', remember: false, nonField: '' };
    return (
        <div className="login-page access-page has-full-screen-bg">
            <div className="upper-wrapper">
                <MiniPageHeader />

                <section className="login-section access-section section">
                    <div className="container">
                        <div className="row">
                            <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                                <div className="form-box-inner">
                                    <h2 className="title text-center">
                                        Login to <span className="d-inline-block">Friendly Reminder</span>
                                    </h2>
                                    <div className="row justify-content-center">
                                        <div className="form-container col-12 col-lg-8">
                                            <Formik
                                                initialValues={initialValues}
                                                onSubmit={onSubmit}
                                                validate={validate}
                                                validateOnMount={true}
                                            >
                                                {({ errors, isSubmitting, isValid }): JSX.Element => (
                                                    <>
                                                        {errors && errors.nonField && (
                                                            <Alert data-testid="errors" color="danger">
                                                                {errors.nonField}
                                                            </Alert>
                                                        )}

                                                        <Form className="login-form">
                                                            <div className="form-group email">
                                                                <FontAwesomeIcon icon="user" />
                                                                <label htmlFor="email" className="sr-only">
                                                                    Email
                                                                </label>
                                                                <Field
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder="Email address"
                                                                    autoFocus
                                                                    required
                                                                    autoComplete="email"
                                                                />
                                                            </div>
                                                            <div className="form-group password">
                                                                <FontAwesomeIcon icon="lock" />
                                                                <label htmlFor="password" className="sr-only">
                                                                    Password
                                                                </label>
                                                                <Field
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="password"
                                                                    name="password"
                                                                    placeholder="Password"
                                                                    required
                                                                    autoComplete="current-password"
                                                                />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="checkbox remember">
                                                                        <label>
                                                                            <Field
                                                                                type="checkbox"
                                                                                id="remember"
                                                                                name="remember"
                                                                                data-testid="remember"
                                                                            />{' '}
                                                                            Remember me
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 text-right">
                                                                    <p className="forgot-password">
                                                                        <Link to="/password/reset">
                                                                            Forgot password?
                                                                        </Link>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <Button
                                                                block
                                                                color="cta-primary"
                                                                type="submit"
                                                                disabled={isSubmitting || !isValid}
                                                                aria-disabled={isSubmitting || !isValid}
                                                                data-testid="submit"
                                                            >
                                                                Login
                                                            </Button>
                                                        </Form>
                                                    </>
                                                )}
                                            </Formik>
                                            <p className="lead">
                                                Don&apos;t have an account yet? <br />
                                                <Link className="signup-link" to="/register">
                                                    Create your account now
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default LoginView;
