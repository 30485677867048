import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/browser';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

import ApiClient from '../api';
import { useDebounce } from '../hooks';
import { GoogleContact, RawGoogleContact, RawGoogleContactName } from '../interfaces';
import { setDocumentTitle } from '../utils';
import { reportEvent } from '../utils/analytics';
import FullpageLoadingIndicator from './FullpageLoadingIndicator';
import MiniPageHeader from './MiniPageHeader';

// NOTE: This is needed to appease TypeScript since gapi is loaded externally.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let gapi: any;

type GoogleAuthorizationProps = {
    onClick: React.MouseEventHandler<HTMLElement>;
};
type GoogleContactSearchProps = {
    onReview: (contacts: Map<string, GoogleContact>) => void;
    selected: Map<string, GoogleContact>;
};

type GoogleContactSearchResultProps = {
    contact: GoogleContact;
    onClick: React.MouseEventHandler<HTMLElement>;
    selected: boolean;
};

type GoogleContactReviewResultProps = {
    contact: GoogleContact;
    onUpdate: (contact: GoogleContact) => void;
};

type ImportReviewProps = {
    contacts: Map<string, GoogleContact>;
    onGoBack: (contacts: Map<string, GoogleContact>) => void;
    onImportFinished: () => void;
};

const GoogleAuthorization: React.FC<GoogleAuthorizationProps> = (props: GoogleAuthorizationProps) => {
    const { onClick } = props;
    return (
        <div>
            <p className="lead">
                Click the button below to authorize Friendly Reminder to access your Google contacts. We will only add
                the friends you select.
            </p>
            <Button block color="cta-primary" onClick={onClick} data-testid="authorize">
                Connect to Google
            </Button>
        </div>
    );
};

const GoogleContactSearchResult: React.FC<GoogleContactSearchResultProps> = (props: GoogleContactSearchResultProps) => {
    const { contact, onClick, selected } = props;
    const phoneNumbers = contact.phoneNumbers.map(pn => pn.phoneNumber);
    const emailAddresses = contact.emailAddresses.map(ea => ea.emailAddress);

    return (
        <tr key={contact.id} data-testid={contact.id} onClick={onClick}>
            <td style={{ width: '90%' }}>
                <span
                    style={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <strong>{contact.fullName}</strong>
                    <div>
                        <FontAwesomeIcon icon="phone" /> {phoneNumbers.join(', ') || <em>None</em>}
                    </div>
                    <div>
                        <FontAwesomeIcon icon="envelope" /> {emailAddresses.join(', ') || <em>None</em>}
                    </div>
                </span>
            </td>
            <td className="align-middle text-right">
                {selected ? (
                    <span role="img" aria-label="check mark">
                        <FontAwesomeIcon icon="check-circle" color="green" />
                    </span>
                ) : (
                    <span>&nbsp;</span>
                )}
            </td>
        </tr>
    );
};

const GoogleContactSearch: React.FC<GoogleContactSearchProps> = (props: GoogleContactSearchProps) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [results, setResults] = useState<GoogleContact[]>([]);
    const [selected, setSelected] = useState<Map<string, GoogleContact>>(props.selected);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    useEffect(() => {
        async function getContacts(query: string): Promise<GoogleContact[]> {
            const response = await gapi.client.request({
                method: 'GET',
                path: '/m8/feeds/contacts/default/full',
                params: {
                    alt: 'json',
                    showdeleted: false,
                    // NOTE: We limit the result count to ensure a responsive experience.
                    'max-results': 100,
                    q: query,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'GData-Version': '3.0',
                },
            });

            console.log(`Retrieved ${response.result.feed['openSearch$totalResults']['$t']} contacts from Google`);

            function getContactInfoType(rel: string): string {
                return new URL(rel).hash.replace('#', '');
            }

            function parseContact(entry: RawGoogleContact): GoogleContact {
                const name = entry['gd$name'] as RawGoogleContactName;
                const emails = entry['gd$email'];
                const phoneNumbers = entry['gd$phoneNumber'];

                return {
                    id: entry['id']['$t'],
                    fullName: name['gd$fullName']['$t'],
                    givenName: name['gd$givenName'] && name['gd$givenName']['$t'],
                    familyName: name['gd$familyName'] && name['gd$familyName']['$t'],
                    emailAddresses: (emails || []).map(email => {
                        return { emailAddress: email.address, type: email.rel && getContactInfoType(email.rel) };
                    }),
                    phoneNumbers: (phoneNumbers || []).map(phoneNumber => {
                        return {
                            phoneNumber: phoneNumber['$t'],
                            type: phoneNumber.rel && getContactInfoType(phoneNumber.rel),
                        };
                    }),
                };
            }

            return (response.result.feed.entry || [])
                .filter((entry: RawGoogleContact) => {
                    return entry['gd$name'];
                })
                .map(parseContact)
                .sort((a: GoogleContact, b: GoogleContact) => {
                    const aName = a.fullName.toLowerCase();
                    const bName = b.fullName.toLowerCase();

                    if (aName < bName) {
                        return -1;
                    }
                    if (aName > bName) {
                        return 1;
                    }
                    return 0;
                });
        }

        setIsSearching(true);
        getContacts(debouncedSearchTerm).then(results => {
            setIsSearching(false);
            setResults(results);
        });
    }, [debouncedSearchTerm]);

    const toggleContact = (contact: GoogleContact): void => {
        const key = contact.id;

        // NOTE: We must clone the Map to trigger a state update.
        if (selected.has(key)) {
            selected.delete(key);
            setSelected(new Map<string, GoogleContact>(selected));
        } else {
            setSelected(new Map<string, GoogleContact>(selected.set(key, contact)));
        }
    };

    return (
        <div>
            <p className="lead">
                Click one or more friends to add. After you have made your selection, click the <strong>Review</strong>{' '}
                button to select your preferred email address and phone number for each friend.
            </p>
            <FormGroup>
                <Label for="query" className="sr-only">
                    Name
                </Label>
                <Input
                    type="text"
                    name="query"
                    id="query"
                    autoFocus
                    autoComplete="off"
                    placeholder="Search contacts"
                    value={searchTerm}
                    onChange={(event): void => setSearchTerm(event.target.value)}
                />
            </FormGroup>
            {isSearching ? (
                <FullpageLoadingIndicator />
            ) : (
                <>
                    <div className="text-center my-3">
                        <em>
                            Found {results.length} {results.length === 1 ? 'friend' : 'friends'}.
                            {selected.size > 0 ? ` Selected ${selected.size}.` : null}
                        </em>
                    </div>
                    <table
                        className="table table-striped table-hover"
                        style={{ cursor: 'pointer', tableLayout: 'fixed' }}
                    >
                        <tbody>
                            {results.map(contact => (
                                <GoogleContactSearchResult
                                    key={contact.id}
                                    contact={contact}
                                    onClick={(): void => toggleContact(contact)}
                                    selected={selected.has(contact.id)}
                                />
                            ))}
                        </tbody>
                    </table>
                    <Button
                        block
                        color="cta-primary"
                        disabled={selected.size < 1}
                        onClick={(): void => props.onReview(selected)}
                    >
                        Review
                    </Button>
                </>
            )}
        </div>
    );
};

const GoogleContactReviewResult: React.FC<GoogleContactReviewResultProps> = (props: GoogleContactReviewResultProps) => {
    const { contact, onUpdate } = props;
    const phoneNumbers = contact.phoneNumbers.map(pn => pn.phoneNumber);
    const emailAddresses = contact.emailAddresses.map(ea => ea.emailAddress);

    function onChangeEmail(contact: GoogleContact): (event: ChangeEvent<HTMLInputElement>) => void {
        return (event: ChangeEvent<HTMLInputElement>): void => {
            contact.selectedEmail = event.target.value;
            onUpdate(contact);
        };
    }

    function onChangePhoneNumber(contact: GoogleContact): (event: ChangeEvent<HTMLInputElement>) => void {
        return (event: ChangeEvent<HTMLInputElement>): void => {
            contact.selectedPhoneNumber = event.target.value;
            onUpdate(contact);
        };
    }

    return (
        <tr key={contact.id} data-testid={contact.id}>
            <td style={{ width: '90%' }}>
                <Form>
                    <span
                        style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <div>
                            <strong>{contact.fullName}</strong>
                        </div>
                        {phoneNumbers.length > 0 ? (
                            <div className="form-group">
                                <FontAwesomeIcon icon="phone" />
                                <label className="sr-only" htmlFor={`phone-number-${contact.id}`}>
                                    Phone number for {contact.fullName}
                                </label>
                                <Input
                                    type="select"
                                    id={`phone-number-${contact.id}`}
                                    onChange={onChangePhoneNumber(contact)}
                                    value={contact.selectedPhoneNumber}
                                >
                                    {phoneNumbers.map(phoneNumber => {
                                        return (
                                            <option key={phoneNumber} value={phoneNumber}>
                                                {phoneNumber}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </div>
                        ) : (
                            <div>
                                <FontAwesomeIcon icon="phone" /> <em>None</em>
                            </div>
                        )}
                        {emailAddresses.length > 0 ? (
                            <div className="form-group">
                                <FontAwesomeIcon icon="envelope" />
                                <label className="sr-only" htmlFor={`email-${contact.id}`}>
                                    Email address for {contact.fullName}
                                </label>
                                <Input
                                    type="select"
                                    id={`email-${contact.id}`}
                                    onChange={onChangeEmail(contact)}
                                    value={contact.selectedEmail}
                                >
                                    {emailAddresses.map(email => {
                                        return (
                                            <option key={email} value={email}>
                                                {email}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </div>
                        ) : (
                            <div>
                                <FontAwesomeIcon icon="envelope" /> <em>None</em>
                            </div>
                        )}
                    </span>
                </Form>
            </td>
        </tr>
    );
};

const ImportReview: React.FC<ImportReviewProps> = (props: ImportReviewProps) => {
    const { contacts, onGoBack, onImportFinished } = props;

    function onContactUpdate(contact: GoogleContact): void {
        contacts.set(contact.id, contact);
    }

    async function createContact(contact: GoogleContact): Promise<boolean> {
        try {
            /* eslint-disable @typescript-eslint/camelcase */
            const unsavedContact = {
                id: '',
                first_name: contact.givenName || '',
                last_name: contact.familyName || '',
                phone_number:
                    contact.selectedPhoneNumber ||
                    (contact.phoneNumbers && contact.phoneNumbers[0] && contact.phoneNumbers[0].phoneNumber) ||
                    '',
                email:
                    contact.selectedEmail ||
                    (contact.emailAddresses && contact.emailAddresses[0] && contact.emailAddresses[0].emailAddress) ||
                    '',
                reminder_frequency_in_days: null,
                next_reminder_at: null,
                note: '',
            };
            /* eslint-enable @typescript-eslint/camelcase */

            await ApiClient.createContact(unsavedContact);

            reportEvent({
                category: 'Friends',
                action: 'Friend created',
            });

            return true;
        } catch (error) {
            // TODO Display an alert
            Sentry.captureException(error);
        }

        return false;
    }

    async function addFriends(): Promise<void> {
        Array.from(contacts.values()).map(async contact => {
            await createContact(contact);
        });

        onImportFinished();
    }

    return (
        <div>
            <p className="lead">
                Select the email address and phone number you want to import for each friend. We will include this
                information in your friendly reminder so that you can quickly get in touch with them. Click the
                <strong>Add Friends</strong> button to finish.
            </p>
            <div className="text-center my-3">
                <em>
                    <Button color="link" onClick={(): void => onGoBack(contacts)}>
                        &lt;&lt; Go Back
                    </Button>
                </em>
            </div>
            <table className="table table-striped table-hover" style={{ cursor: 'pointer', tableLayout: 'fixed' }}>
                <tbody>
                    {Array.from(contacts.values()).map(contact => (
                        <GoogleContactReviewResult key={contact.id} contact={contact} onUpdate={onContactUpdate} />
                    ))}
                </tbody>
            </table>
            <Button block color="cta-primary" onClick={addFriends}>
                Add Friends
            </Button>
        </div>
    );
};

const GoogleImportView: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest'];
    const SCOPES = 'https://www.googleapis.com/auth/contacts.readonly';

    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const [isReviewing, setIsReviewing] = useState<boolean>(false);
    const [selected, setSelected] = useState<Map<string, GoogleContact>>(new Map<string, GoogleContact>());

    const onSignIn = (isSignedIn: boolean): void => {
        if (isSignedIn) {
            reportEvent({
                category: 'User',
                action: 'Authorized Google import',
            });
        }

        setIsAuthorized(isSignedIn);
    };

    async function initializeClient(): Promise<void> {
        try {
            await gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            });
            // Listen for sign-in state changes.
            gapi.auth2.getAuthInstance().isSignedIn.listen(onSignIn);

            // Update the current signed-in state
            const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();

            if (!isSignedIn) {
                gapi.auth2.getAuthInstance().signIn();
            }

            onSignIn(isSignedIn);
        } catch (error) {
            // TODO Render error message to user
            Sentry.captureException(error);
        }
    }

    setDocumentTitle('Add friends from Google');

    const onReview = (contacts: Map<string, GoogleContact>): void => {
        setSelected(contacts);
        setIsReviewing(true);
    };

    const onGoBack = (contacts: Map<string, GoogleContact>): void => {
        setSelected(contacts);
        setIsReviewing(false);
    };

    return (
        <div className="app">
            <MiniPageHeader />
            <div className="container">
                <div className="row">
                    <div className="form-box col-md-10 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">Add friends from Google</h2>
                            {isAuthorized ? (
                                isReviewing ? (
                                    <ImportReview
                                        contacts={selected}
                                        onGoBack={onGoBack}
                                        onImportFinished={(): void => {
                                            props.history.push('/friends');
                                        }}
                                    />
                                ) : (
                                    <GoogleContactSearch selected={selected} onReview={onReview} />
                                )
                            ) : (
                                <GoogleAuthorization
                                    onClick={(): void => gapi.load('client:auth2', initializeClient)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(GoogleImportView);
