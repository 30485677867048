import React from 'react';
import { Link } from 'react-router-dom';

import MiniPageHeader from './MiniPageHeader';

const CheckoutSuccessView: React.FC = () => {
    return (
        <div className="app">
            <MiniPageHeader />
            <div className="container">
                <div className="row">
                    <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">
                                <span role="img" aria-label="party-popper">
                                    🎉
                                </span>{' '}
                                Hey, Friend!
                            </h2>
                            <p className="lead">
                                Your subscription is now active, and the friendly reminders are flowing!
                            </p>
                            <Link to="/friends" className="btn btn-block btn-cta-primary">
                                Add friends
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutSuccessView;
