import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import React, { useState } from 'react';
import { Alert, Button } from 'reactstrap';

import { PasswordChangeData } from '../interfaces';
import * as api from '../utils/api';

type PasswordFormValues = {
    oldpassword: string;
    password1: string;
    password2: string;
    nonField: string;
};

const PasswordForm: React.FC = () => {
    const [succeeded, setSucceeded] = useState<boolean>(false);

    function validate(values: FormikValues): FormikErrors<FormikValues> {
        const errors: FormikErrors<FormikValues> = {};
        if (!values.oldpassword) {
            errors.oldpassword = 'Please type your current password.';
        }

        if (!values.password1) {
            errors.password1 = 'A password is required to keep your information secure';
        }

        if (values.password1 !== values.password2) {
            errors.password2 = 'Your passwords must match';
        }

        return errors;
    }

    function onSubmit(values: FormikValues, helpers: FormikHelpers<PasswordFormValues>): void {
        delete values.nonField;
        const data: PasswordChangeData = {
            oldPassword: values.oldpassword,
            newPassword: values.password1,
        };
        api.changePassword(data)
            .then(async () => {
                helpers.setSubmitting(false);
                helpers.setFieldError('nonField', '');
                setSucceeded(true);
            })
            .catch((error): void => {
                helpers.setSubmitting(false);
                const form = error && error.response && error.response.data && error.response.data.form;
                const errors = form && form.errors;

                for (const field of Object.keys(form.fields)) {
                    helpers.setFieldError(field as keyof PasswordFormValues, form.fields[field].errors);
                }

                const errorMsg = errors
                    ? errors[0]
                    : 'An error occurred while changing your password. Please try again.';
                helpers.setFieldError('nonField', errorMsg);

                setSucceeded(false);
            });
    }

    const initialValues = {
        oldpassword: '',
        password1: '',
        password2: '',
        nonField: '',
    };
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {({ errors, isSubmitting, isValid }): JSX.Element => (
                <>
                    {errors && errors.nonField && (
                        <Alert data-testid="errors" color="danger">
                            {errors.nonField}
                        </Alert>
                    )}
                    {succeeded && (
                        <Alert data-testid="successAlert" color="success">
                            Your password has been changed.
                        </Alert>
                    )}
                    <Form>
                        <div className="form-group">
                            <FontAwesomeIcon icon="lock" />
                            <label className="sr-only" htmlFor="oldpassword">
                                Current password
                            </label>
                            <Field
                                type="password"
                                className={`form-control ${errors.oldpassword && 'is-invalid'}`}
                                id="oldpassword"
                                name="oldpassword"
                                placeholder="Current password"
                                autoComplete="current-password"
                            />
                            {errors.oldpassword && <div className="invalid-feedback">{errors.oldpassword}</div>}
                        </div>
                        <div className="form-group">
                            <FontAwesomeIcon icon="lock" />
                            <label className="sr-only" htmlFor="password1">
                                New password
                            </label>
                            <Field
                                type="password"
                                className={`form-control ${errors.password1 && 'is-invalid'}`}
                                id="password1"
                                name="password1"
                                placeholder="New password"
                                autoComplete="new-password"
                            />
                            {errors.password1 && <div className="invalid-feedback">{errors.password1}</div>}
                        </div>
                        <div className="form-group">
                            <FontAwesomeIcon icon="check-double" />
                            <label className="sr-only" htmlFor="password2">
                                Confirm new password
                            </label>
                            <Field
                                type="password"
                                className={`form-control ${errors.password2 && 'is-invalid'}`}
                                id="password2"
                                name="password2"
                                placeholder="Confirm new password"
                                autoComplete="new-password"
                            />
                            {errors.password2 && <div className="invalid-feedback">{errors.password2}</div>}
                        </div>
                        <Button
                            type="submit"
                            color="cta-primary"
                            style={{ marginRight: '.25rem' }}
                            disabled={isSubmitting || !isValid}
                            aria-disabled={isSubmitting || !isValid}
                            data-testid="submit-password"
                        >
                            Change password
                        </Button>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default PasswordForm;
