import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import GoogleAnalytics, { EventArgs, FieldsObject } from 'react-ga';
import { RouteProps } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';

export const initializeAnalytics = (): void => {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
        GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    }
};

const isAnalyticsEnabled = (): boolean => {
    return !!process.env.REACT_APP_GA_TRACKING_ID;
};

export const withTracker = <P extends RouteComponentProps>(
    WrappedComponent: React.ComponentType<P>,
    options: FieldsObject = {},
): ((props: P) => JSX.Element) => {
    const trackPage = (page: string): void => {
        if (isAnalyticsEnabled()) {
            ReactGA.set({ page, ...options });
            ReactGA.pageview(page);
        }
    };

    // eslint-disable-next-line react/display-name
    return (props: P): JSX.Element => {
        useEffect(() => {
            trackPage(props.location.pathname);
        }, [props.location.pathname]);

        return <WrappedComponent {...props} />;
    };
};

export const RouteWithTracker: React.FC<RouteProps> = (props: RouteProps) => {
    const modifiedProps = Object.assign({}, props);
    if (props.component) {
        modifiedProps.component = withTracker(props.component);
    }
    return <Route {...modifiedProps} />;
};

export function reportEvent(args: EventArgs): void {
    ReactGA.event(args);
}
