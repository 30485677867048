import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import AppProviders from './context';
import './scss/main.scss';
import * as serviceWorker from './serviceWorker';
import { setupAxios, setupFontAwesome } from './utils';
import { initializeAnalytics } from './utils/analytics';
import { clearUserInfoCache } from './utils/api';

initializeAnalytics();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV || 'development',
    release: `${process.env.REACT_APP_SENTRY_PROJECT_NAME}@${process.env.REACT_APP_SOURCE_VERSION}`,
});

setupAxios();
setupFontAwesome();

ReactDOM.render(
    <AppProviders>
        <App />
    </AppProviders>,
    document.getElementById('root'),
);

window.addEventListener('unload', function() {
    // We clear the user info cache on unload so that users can simply
    // refresh the page to refresh data, if needed. When in doubt, restart!
    clearUserInfoCache();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
