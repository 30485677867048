import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';

import { PasswordSetFormData } from '../interfaces';
import { setDocumentTitle } from '../utils';
import { reportEvent } from '../utils/analytics';
import * as api from '../utils/api';
import { PASSWORD_SET_PATH } from '../utils/api';
import MiniPageHeader from './MiniPageHeader';

type FormErrors = {
    nonField: string;
    password1: string;
    password2: string;
};
type PasswordSetFormProps = { userId: string };

const PasswordSetForm: React.FC<PasswordSetFormProps> = (props: PasswordSetFormProps) => {
    const [succeeded, setSucceeded] = useState<boolean>(false);

    function onSubmit(values: FormikValues, helpers: FormikHelpers<FormErrors>): void {
        const data: PasswordSetFormData = {
            password: values.password1,
            token: PASSWORD_SET_PATH,
            userId: props.userId,
        };
        api.passwordSet(data)
            .then((): void => {
                helpers.setSubmitting(false);
                helpers.setFieldError('nonField', '');

                reportEvent({
                    category: 'User',
                    action: 'Password reset succeeded',
                });

                setSucceeded(true);
            })
            .catch((error): void => {
                helpers.setSubmitting(false);
                const form = error && error.response && error.response.data && error.response.data.form;
                const errors = form && form.errors;

                for (const field of Object.keys(form.fields)) {
                    helpers.setFieldError(field as keyof FormErrors, form.fields[field].errors);
                }

                const errorMsg = errors ? errors[0] : 'An error occurred. Please try again.';
                helpers.setFieldError('nonField', errorMsg);
                setSucceeded(false);
            });
    }

    function validate(values: FormikValues): FormikErrors<FormikValues> {
        const errors: FormikErrors<FormikValues> = {};

        if (!values.password1) {
            errors.password1 = 'A password is required to keep your information secure';
        }

        if (values.password1 !== values.password2) {
            errors.password2 = 'Your passwords must match';
        }

        return errors;
    }

    const initialValues = { password1: '', password2: '', nonField: '' };

    return (
        <div className="setpass-page access-page has-full-screen-bg">
            <div className="upper-wrapper">
                <MiniPageHeader />
                <section className="resetpass-section access-section section">
                    <div className="container">
                        <div className="row">
                            <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                                <div className="form-box-inner">
                                    <h2 className="title text-center">Set Password</h2>
                                    <p className="intro">Please enter your new password.</p>

                                    <div className="form-container">
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={onSubmit}
                                            validate={validate}
                                            validateOnBlur={false}
                                        >
                                            {({ errors, isSubmitting, isValid }): JSX.Element => (
                                                <>
                                                    {errors && errors.nonField && (
                                                        <Alert data-testid="errors" color="danger">
                                                            {errors.nonField} If you continue seeing errors, please{' '}
                                                            <Link to="/password/reset">
                                                                request another reset email
                                                            </Link>
                                                            .
                                                        </Alert>
                                                    )}

                                                    {succeeded && (
                                                        <Alert data-testid="successAlert" color="success">
                                                            Your password has been reset. You may now{' '}
                                                            <Link to="/login">login</Link>.
                                                        </Alert>
                                                    )}

                                                    <Form>
                                                        <div className="form-group password">
                                                            <FontAwesomeIcon icon="lock" />
                                                            <label htmlFor="password1" className="sr-only">
                                                                New password
                                                            </label>
                                                            <Field
                                                                type="password"
                                                                className={`form-control ${errors.password1 &&
                                                                    'is-invalid'}`}
                                                                id="password1"
                                                                name="password1"
                                                                placeholder="New password"
                                                                autoComplete="new-password"
                                                                autoFocus
                                                            />
                                                            {errors.password1 && (
                                                                <div className="invalid-feedback">
                                                                    {errors.password1}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group password">
                                                            <FontAwesomeIcon icon="check-double" />
                                                            <label htmlFor="password2" className="sr-only">
                                                                Confirm new password
                                                            </label>
                                                            <Field
                                                                type="password"
                                                                className={`form-control ${errors.password2 &&
                                                                    'is-invalid'}`}
                                                                id="password2"
                                                                name="password2"
                                                                placeholder="Confirm new password"
                                                                autoComplete="new-password"
                                                            />
                                                            {errors.password2 && (
                                                                <div className="invalid-feedback">
                                                                    {errors.password2}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Button
                                                            block
                                                            color="cta-primary"
                                                            type="submit"
                                                            disabled={isSubmitting || !isValid}
                                                            aria-disabled={isSubmitting || !isValid}
                                                            data-testid="submit"
                                                        >
                                                            Set Password
                                                        </Button>
                                                    </Form>
                                                </>
                                            )}
                                        </Formik>

                                        <p className="lead text-center">
                                            I need a new <Link to="/password/reset">reset link</Link>
                                            <br />
                                            Take me back to the <Link to="/login">login</Link> page
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

type MatchParams = {
    token: string;
    userId: string;
};
type PasswordSetViewProps = RouteComponentProps<MatchParams>;

const PasswordSetView: React.FC<PasswordSetViewProps> = (props: PasswordSetViewProps) => {
    setDocumentTitle('Set Password');
    return <PasswordSetForm userId={props.match.params.userId} />;
};

export default withRouter(PasswordSetView);
