import React from 'react';

import AppFooter from './components/AppFooter';
import NavigationBar from './components/NavigationBar';

const AppChrome: React.FC = props => {
    return (
        <>
            <NavigationBar />
            <main role="main">
                {/* TODO Restore alerts */}
                {/*<SubscriptionInactiveAlert />*/}
                {/*<PhoneNumberVerificationAlert />*/}
                {props.children}
            </main>
            <AppFooter />
        </>
    );
};

export default AppChrome;
