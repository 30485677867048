import React from 'react';
import { useAsync } from 'react-async';

import FullpageLoadingIndicator from '../components/FullpageLoadingIndicator';
import { LoginFormData, ProfileUpdateData, RegisterFormData, User } from '../interfaces';
import * as api from '../utils/api';
import { setUserInCache } from '../utils/api';

interface AuthContextData {
    user: User | null;
    login: (data: LoginFormData) => Promise<Record<string, string> | void>;
    logout: () => Promise<boolean | void>;
    refreshUserData: () => Promise<void>;
    register: (data: RegisterFormData) => Promise<Record<string, string> | void>;
    updateUser: (userData: ProfileUpdateData) => Promise<Record<string, string> | void>;
}

const AuthContext = React.createContext<AuthContextData>({
    user: null,
    login: (): Promise<Record<string, string> | void> => Promise.resolve({}),
    logout: (): Promise<boolean | void> => Promise.resolve(false),
    refreshUserData: (): Promise<void> => Promise.resolve(),
    register: (): Promise<Record<string, string> | void> => Promise.resolve({}),
    updateUser: (): Promise<Record<string, string> | void> => Promise.resolve({}),
});

function AuthProvider(props: any) {
    const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
    const { data = null, error, isRejected, isPending, isSettled, reload } = useAsync({
        promiseFn: api.getUser,
        refresh: false,
    });

    React.useLayoutEffect(() => {
        if (isSettled) {
            setFirstAttemptFinished(true);
        }
    }, [isSettled]);

    if (!firstAttemptFinished) {
        if (isPending) {
            return <FullpageLoadingIndicator />;
        }
        if (isRejected) {
            return (
                <div style={{ color: 'red' }}>
                    <p>Something went wrong. Try refreshing the app.</p>
                    <pre>{error && error.message}</pre>
                </div>
            );
        }
    }

    function login(loginData: LoginFormData): Promise<Record<string, string> | void> {
        return api.login(loginData).then(reload);
    }

    function logout(): Promise<boolean | void> {
        return api.logout().then(reload);
    }

    function register(registerData: RegisterFormData): Promise<Record<string, string> | void> {
        return api.register(registerData).then(reload);
    }

    function updateUser(userData: ProfileUpdateData): Promise<Record<string, string> | void> {
        return api.updateUser(userData).then(response => {
            setUserInCache(JSON.stringify(response.data));
            reload();
        });
    }

    function refreshUserData(): Promise<void> {
        return api.getUser({ refresh: true }).then(() => {
            reload();
        });
    }

    return (
        <AuthContext.Provider value={{ user: data, login, logout, refreshUserData, register, updateUser }} {...props} />
    );
}

function useAuth(): AuthContextData {
    const context = React.useContext(AuthContext);

    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }

    return context;
}

export { AuthProvider, useAuth };
