import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/browser';
import currency from 'currency.js';
import { Field, Form, Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';

import ApiClient from '../api';
import { useAuth } from '../context/auth';
import { ProfileUpdateData, User } from '../interfaces';
import { setDocumentTitle } from '../utils';
import { getUser } from '../utils/api';
import MiniPageHeader from './MiniPageHeader';
import PasswordForm from './PasswordForm';

type UserFormValues = ProfileUpdateData & { last_name: string; phone_number: string; nonField: string };

const UserForm: React.FC = () => {
    const { updateUser, user } = useAuth();

    function getValuesFromUser(user: User): UserFormValues {
        return {
            email: user.email || '',
            /* eslint-disable @typescript-eslint/camelcase */
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            phone_number: user.phone_number || '',
            /* eslint-enable @typescript-eslint/camelcase */
            nonField: '',
        };
    }

    async function onSubmit(values: FormikValues, helpers: FormikHelpers<UserFormValues>): Promise<void> {
        delete values.nonField;
        updateUser(values as ProfileUpdateData)
            .then(async () => {
                helpers.setSubmitting(false);
                helpers.setFieldError('nonField', '');
                helpers.setValues(getValuesFromUser((await getUser({ refresh: false })) as User));
            })
            .catch((error): void => {
                helpers.setSubmitting(false);
                const errors =
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.form &&
                    error.response.data.form.errors;

                const errorMsg = errors ? errors[0] : 'An error occurred while updating your info.';
                helpers.setFieldError('nonField', errorMsg);
            });
    }

    function validate(values: FormikValues): FormikErrors<FormikValues> {
        const errors: FormikErrors<FormikValues> = {};
        if (!values.first_name) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            errors.first_name = 'A first name is required';
        }

        return errors;
    }

    const initialValues = getValuesFromUser(user as User);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} validateOnMount={true}>
            {({ errors, isSubmitting, isValid }): JSX.Element => (
                <>
                    {errors && errors.nonField && (
                        <Alert data-testid="errors" color="danger">
                            {errors.nonField}
                        </Alert>
                    )}
                    <Form>
                        <div className="form-group">
                            <FontAwesomeIcon icon="user" />
                            <label className="sr-only" htmlFor="first_name">
                                First Name
                            </label>
                            <Field
                                type="text"
                                className="form-control"
                                id="first_name"
                                name="first_name"
                                placeholder="First name"
                            />
                        </div>
                        <div className="form-group">
                            <FontAwesomeIcon icon="user" />
                            <label className="sr-only" htmlFor="last_name">
                                Last Name
                            </label>
                            <Field
                                type="text"
                                className="form-control"
                                id="last_name"
                                name="last_name"
                                placeholder="Last name"
                            />
                        </div>
                        <div className="form-group">
                            <FontAwesomeIcon icon="envelope" />
                            <label className="sr-only" htmlFor="email">
                                Email
                            </label>
                            <Field type="text" className="form-control" id="email" name="email" placeholder="Email" />
                        </div>
                        <div className="form-group">
                            <FontAwesomeIcon icon="phone" />
                            <label className="sr-only" htmlFor="phone_number">
                                Phone Number
                            </label>
                            <Field
                                type="tel"
                                className="form-control"
                                id="phone_number"
                                name="phone_number"
                                placeholder="Phone number"
                            />
                        </div>
                        <Button
                            type="submit"
                            color="cta-primary"
                            style={{ marginRight: '.25rem' }}
                            disabled={isSubmitting || !isValid}
                            aria-disabled={isSubmitting || !isValid}
                            data-testid="submit-profile"
                        >
                            Update profile
                        </Button>
                    </Form>
                </>
            )}
        </Formik>
    );
};

const BillingSection: React.FC = () => {
    const { user } = useAuth();
    const { subscription } = user as User;

    async function redirectToStripePortal(): Promise<void> {
        const response = await ApiClient.createStripePortalSession();
        const session = response.data;

        if (!session.url) {
            Sentry.captureMessage('Stripe portal session missing URL', Sentry.Severity.Error);
            return;
        }

        // NOTE: The user will return to this page after making changes to the subscription.
        // When they return we will refresh the user data automatically since the app will "reboot".
        window.location.href = session.url;
    }

    if (subscription) {
        const currentPeriodEnd = moment(subscription.current_period_end)
            .startOf('day')
            .fromNow();
        const plan = subscription.plan;
        const interval = plan.interval === 'year' ? 'yearly' : 'monthly';
        const status = subscription.status === 'trialing' ? 'trialing' : 'active';
        const invoiceAmount = currency(plan.amount).format(true);

        return (
            <div>
                <p>
                    Your {interval} subscription is {status}. Your next invoice of {invoiceAmount} will be sent{' '}
                    {currentPeriodEnd}.
                </p>
                <p className="pt-4">
                    <h6>Manage subscription</h6>
                    <div>
                        Click the button below to visit the billing portal to cancel your subscription or change plans.
                        You can also view previous invoices.
                    </div>
                </p>
                <Button color="cta-primary" onClick={redirectToStripePortal}>
                    Manage subscription
                </Button>
            </div>
        );
    }

    return (
        <div>
            <p>You do not have an active subscription, and are not receiving friendly reminders.</p>
            <Link role="button" className="btn btn-block btn-cta-primary" to="/pricing">
                Start a subscription
            </Link>
        </div>
    );
};

const UserEditView: React.FC = () => {
    setDocumentTitle('User Profile');

    return (
        <div className="app">
            <MiniPageHeader />
            <div className="container">
                <div className="row">
                    <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">Edit Personal Info</h2>
                            <UserForm />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">Change Password</h2>
                            <PasswordForm />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                        <div className="form-box-inner">
                            <h2 className="title text-center">Billing</h2>
                            <BillingSection />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserEditView;
