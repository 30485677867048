import React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { PasswordSetFormData } from '../interfaces';
import { setDocumentTitle } from '../utils';
import * as api from '../utils/api';
import { PASSWORD_SET_PATH } from '../utils/api';
import FullpageLoadingIndicator from './FullpageLoadingIndicator';

type MatchParams = {
    token: string;
    userId: string;
};

type PasswordSetInterstitialViewProps = RouteComponentProps<MatchParams>;

const PasswordSetInterstitialView: React.FC<PasswordSetInterstitialViewProps> = (
    props: PasswordSetInterstitialViewProps,
) => {
    setDocumentTitle('Set Password');
    const { userId, token } = props.match.params;
    const data: PasswordSetFormData = { token, userId };

    api.passwordSet(data).then((): void => {
        props.history.replace(`/password/reset/key/${userId}-${PASSWORD_SET_PATH}`);
    });

    return <FullpageLoadingIndicator />;
};

export default withRouter(PasswordSetInterstitialView);
