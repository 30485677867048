import React from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AppChrome from './AppChrome';
import CheckoutCancelledView from './components/CheckoutCancelledView';
import CheckoutSuccessView from './components/CheckoutSuccessView';
import ConfirmEmailView from './components/ConfirmEmailView';
import LandingPageView from './components/LandingPageView';
import LoginView from './components/LoginView';
import PasswordResetView from './components/PasswordResetView';
import PasswordSetInterstitialView from './components/PasswordSetInterstitialView';
import PasswordSetView from './components/PasswordSetView';
import PricingView from './components/PricingView';
import PrivacyPageView from './components/PrivacyPageView';
import RegisterView from './components/RegisterView';
import { MatchParams } from './interfaces';
import { RouteWithTracker } from './utils/analytics';

const Password: React.FC<RouteComponentProps<MatchParams>> = ({ match }: RouteComponentProps<MatchParams>) => {
    return (
        <Switch>
            <RouteWithTracker exact strict path={`${match.path}/reset`} component={PasswordResetView} />
            <RouteWithTracker path={`${match.path}/reset/key/:userId-set-password`} component={PasswordSetView} />
            <RouteWithTracker path={`${match.path}/reset/key/:userId-:token`} component={PasswordSetInterstitialView} />
        </Switch>
    );
};

const UnauthenticatedApp: React.FC = () => {
    return (
        <Router>
            <AppChrome>
                <Switch>
                    <RouteWithTracker exact path="/" component={LandingPageView} />
                    <RouteWithTracker path="/confirm-email/:key" component={ConfirmEmailView} />
                    <RouteWithTracker exact strict path="/login" component={LoginView} />
                    <RouteWithTracker path="/password" component={Password} />
                    <RouteWithTracker exact path="/pricing" component={PricingView} />
                    <RouteWithTracker exact path="/privacy" component={PrivacyPageView} />
                    <RouteWithTracker exact path="/purchase/cancel" component={CheckoutCancelledView} />
                    <RouteWithTracker exact path="/purchase/success" component={CheckoutSuccessView} />
                    <RouteWithTracker exact strict path="/register" component={RegisterView} />
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </AppChrome>
        </Router>
    );
};

export default UnauthenticatedApp;
