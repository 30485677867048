import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../context/auth';

const MiniPageHeader: React.FC = () => {
    const { user } = useAuth();
    const to = user ? '/friends' : '/';
    return (
        <header className="header mini-header">
            <div className="container">
                <h1 className="logo">
                    <Link to={to}>
                        <span className="text">Friendly Reminder</span>
                    </Link>
                </h1>
            </div>
        </header>
    );
};

export default MiniPageHeader;
