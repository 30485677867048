import React from 'react';

import AuthenticatedApp from './AuthenticatedApp';
import { useUser } from './context/user';
import UnauthenticatedApp from './UnauthenticatedApp';

const App: React.FC = () => {
    const user = useUser();
    const innerApp = user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
    return <>{innerApp}</>;
};

export default App;
