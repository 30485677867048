import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';

import { useAuth } from '../context/auth';
import { RegisterFormData, SetSubmitting } from '../interfaces';
import { setDocumentTitle } from '../utils';
import { reportEvent } from '../utils/analytics';
import MiniPageHeader from './MiniPageHeader';

const RegisterView: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [submitErrors, setSubmitErrors] = useState<Record<string, string[]>>({
        nonField: [],
        email: [],
        // eslint-disable-next-line @typescript-eslint/camelcase
        first_name: [],
        password1: [],
        password2: [],
        // eslint-disable-next-line @typescript-eslint/camelcase
        phone_number: [],
    });

    const { register } = useAuth();
    setDocumentTitle('Sign Up');

    function onSubmit(values: FormikValues, { setSubmitting }: { setSubmitting: SetSubmitting }): void {
        const formData: RegisterFormData = {
            email: values.email,
            firstName: values.first_name,
            password: values.password1,
            phoneNumber: values.phone_number,
        };

        register(formData)
            .then((): void => {
                reportEvent({
                    category: 'User',
                    action: 'Account created',
                });
                setSubmitting(false);
            })
            .catch((error): void => {
                setSubmitting(false);
                const form = error && error.response && error.response.data && error.response.data.form;
                const formErrors = form && error.response.data.form.errors;
                const fieldErrors: Record<string, string[]> = {};

                for (const field of Object.keys(form.fields)) {
                    fieldErrors[field as string] = form.fields[field].errors;
                }
                setSubmitErrors(fieldErrors);

                if (formErrors) {
                    setError(formErrors[0]);
                } else {
                    setError('The email and password entered are incorrect.');
                }
            });
    }

    function validate(values: FormikValues): FormikErrors<FormikValues> {
        const errors: FormikErrors<FormikValues> = {};

        // Only display validation errors if the user has started entering data.
        // Otherwise, simply disable the submit button. This ensures we don't display
        // an error when users attempt to click on the login link.
        if (![values.email, values.first_name, values.password1, values.password2].some(value => !!value)) {
            errors.nonField = '';
            return errors;
        }

        if (!values.email) {
            errors.email = 'A valid email address is required to sign up.';
        }
        if (!values.first_name) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            errors.first_name = "We don't want to be rude. What should we call you?";
        }

        if (!values.password1) {
            errors.password1 = 'A password is required to keep your information secure';
        }

        if (!values.password2) {
            errors.password2 = 'Please confirm your password';
        }

        if (values.password1 !== values.password2) {
            errors.password2 = 'Your passwords must match';
        }

        return errors;
    }

    // eslint-disable-next-line @typescript-eslint/camelcase
    const initialValues = { nonField: '', email: '', first_name: '', password1: '', password2: '', phone_number: '' };
    return (
        <div className="signup-page access-page has-full-screen-bg">
            <div className="upper-wrapper">
                <MiniPageHeader />
                <section className="resetpass-section access-section section">
                    <div className="container">
                        <div className="row">
                            <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                                <div className="form-box-inner">
                                    <h2 className="title text-center">Sign up</h2>
                                    <p className="intro">Create your account to start receiving friendly reminders.</p>

                                    <div className="form-container">
                                        {error && (
                                            <Alert data-testid="errors" color="danger">
                                                {error}
                                            </Alert>
                                        )}
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={onSubmit}
                                            validate={validate}
                                            validateOnMount={true}
                                        >
                                            {({ errors, isSubmitting, isValid, touched }): JSX.Element => {
                                                const nonInitialErrors: Record<string, string | null> = {};
                                                for (const field of Object.keys(errors)) {
                                                    if ((touched as Record<string, boolean>)[field]) {
                                                        nonInitialErrors[field] =
                                                            submitErrors[field] && submitErrors[field].length > 0
                                                                ? (submitErrors[field][0] as string)
                                                                : ((errors as Record<string, string>)[field] as string);
                                                    } else {
                                                        nonInitialErrors[field] = null;
                                                    }
                                                }

                                                return (
                                                    <Form>
                                                        <div className="form-group">
                                                            <FontAwesomeIcon icon="envelope" />
                                                            <label htmlFor="email" className="sr-only">
                                                                Email address
                                                            </label>
                                                            <Field
                                                                type="email"
                                                                className={`form-control ${nonInitialErrors.email &&
                                                                    'is-invalid'}`}
                                                                id="email"
                                                                name="email"
                                                                placeholder="Email address"
                                                                autoFocus
                                                                required
                                                                autoComplete="email"
                                                                maxLength={150}
                                                            />
                                                            {nonInitialErrors.email && (
                                                                <div className="invalid-feedback">
                                                                    {nonInitialErrors.email}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <FontAwesomeIcon icon="phone" />
                                                            <label htmlFor="phone_number" className="sr-only">
                                                                Phone number
                                                            </label>
                                                            <Field
                                                                type="tel"
                                                                className={`form-control ${nonInitialErrors.phone_number &&
                                                                    'is-invalid'}`}
                                                                id="phone_number"
                                                                name="phone_number"
                                                                placeholder="Phone number"
                                                                required
                                                                autoComplete="tel"
                                                                maxLength={50}
                                                            />
                                                            {nonInitialErrors.phone_number && (
                                                                <div className="invalid-feedback">
                                                                    {nonInitialErrors.phone_number}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <FontAwesomeIcon icon="user" />
                                                            <label htmlFor="first_name" className="sr-only">
                                                                Given name
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${nonInitialErrors.first_name &&
                                                                    'is-invalid'}`}
                                                                id="first_name"
                                                                name="first_name"
                                                                placeholder="Given name"
                                                                required
                                                                autoComplete="given-name"
                                                                maxLength={30}
                                                            />
                                                            {nonInitialErrors.first_name && (
                                                                <div className="invalid-feedback">
                                                                    {nonInitialErrors.first_name}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <FontAwesomeIcon icon="lock" />
                                                            <label htmlFor="password1" className="sr-only">
                                                                Password
                                                            </label>
                                                            <Field
                                                                type="password"
                                                                className={`form-control ${nonInitialErrors.password1 &&
                                                                    'is-invalid'}`}
                                                                id="password1"
                                                                name="password1"
                                                                placeholder="Password"
                                                                required
                                                                autoComplete="new-password"
                                                            />
                                                            {nonInitialErrors.password1 && (
                                                                <div className="invalid-feedback">
                                                                    {nonInitialErrors.password1}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <FontAwesomeIcon icon="check-double" />
                                                            <label htmlFor="password2" className="sr-only">
                                                                Confirm password
                                                            </label>
                                                            <Field
                                                                type="password"
                                                                className={`form-control ${nonInitialErrors.password2 &&
                                                                    'is-invalid'}`}
                                                                id="password2"
                                                                name="password2"
                                                                placeholder="Confirm Password"
                                                                required
                                                                autoComplete="new-password"
                                                            />
                                                            {nonInitialErrors.password2 && (
                                                                <div className="invalid-feedback">
                                                                    {nonInitialErrors.password2}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Button
                                                            block
                                                            type="submit"
                                                            color="cta-primary"
                                                            disabled={isSubmitting || !isValid}
                                                            aria-disabled={isSubmitting || !isValid}
                                                            data-testid="submit"
                                                        >
                                                            Sign Up
                                                        </Button>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>

                                        <p className="lead">
                                            Already have an account? <Link to="/login">Login</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default RegisterView;
