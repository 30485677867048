import React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth } from '../context/auth';
import { setDocumentTitle } from '../utils';

const LogoutView: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const { logout } = useAuth();
    setDocumentTitle('Logout');
    logout().then(() => props.history.push('/'));
    return <div />;
};

export default withRouter(LogoutView);
