import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';

import { PasswordResetFormData } from '../interfaces';
import { setDocumentTitle } from '../utils';
import { reportEvent } from '../utils/analytics';
import * as api from '../utils/api';
import MiniPageHeader from './MiniPageHeader';

type FormErrors = PasswordResetFormData & { nonField: string };

const PasswordResetView: React.FC = () => {
    const [succeeded, setSucceeded] = useState<boolean>(false);

    setDocumentTitle('Password Reset');

    function onSubmit(values: FormikValues, helpers: FormikHelpers<FormErrors>): void {
        delete values.nonField;
        api.passwordReset(values as PasswordResetFormData)
            .then((): void => {
                helpers.setSubmitting(false);
                helpers.setFieldError('nonField', '');

                reportEvent({
                    category: 'User',
                    action: 'Password reset requested',
                });

                setSucceeded(true);
            })
            .catch((error): void => {
                helpers.setSubmitting(false);
                const errors =
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.form &&
                    error.response.data.form.errors;

                const errorMsg = errors ? errors[0] : 'An error occurred. Please try again.';
                helpers.setFieldError('nonField', errorMsg);
                setSucceeded(false);
            });
    }

    function validate(values: FormikValues): FormikErrors<FormikValues> {
        const errors: FormikErrors<FormikValues> = {};

        if (!values.email) {
            errors.email = 'Required';
        }

        return errors;
    }

    const initialValues = { email: '', nonField: '' };
    return (
        <div className="resetpass-page access-page has-full-screen-bg">
            <div className="upper-wrapper">
                <MiniPageHeader />
                <section className="resetpass-section access-section section">
                    <div className="container">
                        <div className="row">
                            <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                                <div className="form-box-inner">
                                    <h2 className="title text-center">Password Reset</h2>
                                    <p className="intro">
                                        Please enter your email address below and we&apos;ll email you a link to a page
                                        where you can easily create a new password.
                                    </p>

                                    <div className="form-container">
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={onSubmit}
                                            validate={validate}
                                            validateOnMount={true}
                                        >
                                            {({ errors, isSubmitting, isValid }): JSX.Element => (
                                                <>
                                                    {errors && errors.nonField && (
                                                        <Alert data-testid="errors" color="danger">
                                                            {errors.nonField}
                                                        </Alert>
                                                    )}

                                                    {succeeded && (
                                                        <Alert data-testid="successAlert" color="success">
                                                            Your password reset request has been received. Check your
                                                            email to continue.
                                                        </Alert>
                                                    )}

                                                    <Form>
                                                        <div className="form-group email">
                                                            <FontAwesomeIcon icon="user" />
                                                            <label htmlFor="email" className="sr-only">
                                                                Email
                                                            </label>
                                                            <Field
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                name="email"
                                                                placeholder="Email address"
                                                                autoFocus
                                                                required
                                                                autoComplete="email"
                                                            />
                                                        </div>
                                                        <Button
                                                            block
                                                            color="cta-primary"
                                                            type="submit"
                                                            disabled={isSubmitting || !isValid}
                                                            aria-disabled={isSubmitting || !isValid}
                                                            data-testid="submit"
                                                        >
                                                            Reset Password
                                                        </Button>
                                                    </Form>
                                                </>
                                            )}
                                        </Formik>

                                        <p className="lead text-center">
                                            Take me back to the <Link to="/login">login</Link> page
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PasswordResetView;
