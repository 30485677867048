import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FullsizePicture, Picture } from 'react-responsive-picture';
import { Link } from 'react-router-dom';
import { Carousel, CarouselItem } from 'reactstrap';

import slide1JPEG from '../images/bg-slide-1.jpg';
import slide1WEBP from '../images/bg-slide-1.webp';
import slide2JPEG from '../images/bg-slide-2.jpg';
import slide2WEBP from '../images/bg-slide-2.webp';
import slide3JPEG from '../images/bg-slide-3.jpg';
import slide3WEBP from '../images/bg-slide-3.webp';
import clintonbPNG from '../images/clintonb-200x200.png';
import clintonbWEBP from '../images/clintonb.webp';
import { resetDocumentTitle } from '../utils';

const HeaderCarousel: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const items = [
        {
            src: slide1JPEG,
            srcWebp: slide1WEBP,
            altText: 'Friends laughing at table',
        },
        {
            src: slide2JPEG,
            srcWebp: slide2WEBP,
            altText: 'Woman using table for video chat',
        },
        {
            src: slide3JPEG,
            srcWebp: slide3WEBP,
            altText: 'Two friends looking at mobile phone',
        },
    ];

    const next = (): void => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = (): void => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const slides = items.map(item => {
        return (
            <CarouselItem
                onExiting={(): void => setAnimating(true)}
                onExited={(): void => setAnimating(false)}
                key={item.src}
            >
                <FullsizePicture
                    sources={[
                        { srcSet: item.srcWebp, type: 'image/webp' },
                        { srcSet: `${item.src} 1400w`, type: 'image/png' },
                    ]}
                    alt={item.altText}
                />
            </CarouselItem>
        );
    });

    return (
        <Carousel activeIndex={activeIndex} next={next} previous={previous} ride="carousel" className="carousel-fade">
            {slides}
        </Carousel>
    );
};

const LandingPageView: React.FC = () => {
    resetDocumentTitle();

    return (
        <>
            <div className="bg-slider-wrapper">
                <HeaderCarousel />
            </div>
            <section className="promo section section-on-bg">
                <div className="container text-center">
                    <h2 className="title">Stay in touch with friends and family!</h2>
                    <p className="intro">Get daily and weekly reminders to stay in touch with your loved ones.</p>
                    <p>
                        <Link className="btn btn-cta btn-cta-primary" to="/register">
                            Try Friendly Reminder
                        </Link>
                    </p>
                </div>
            </section>

            <div className="sections-wrapper">
                <section className="section testimonials">
                    <div className="container">
                        <h2 className="title text-center">Why did I build Friendly Reminder?</h2>
                        <div id="testimonials-carousel">
                            <div className="carousel-inner" role="listbox">
                                <div className="carousel-item item active">
                                    <figure className="profile">
                                        <Picture
                                            sources={[
                                                { srcSet: `${clintonbPNG} 200w`, type: 'image/png' },
                                                { srcSet: clintonbWEBP, type: 'image/webp' },
                                            ]}
                                            alt="Portrait of Clinton Blackburn"
                                        />
                                    </figure>
                                    <div className="content">
                                        <blockquote>
                                            <FontAwesomeIcon icon="quote-left" />
                                            <p>
                                                Life got busy. When my wife and I first moved to the Bay Area, we
                                                struggled to make new friends and rebuild our social support system. Two
                                                years later we have an amazing group of friends, and now find ourselves
                                                struggling to maintain connections with new friends and old friends back
                                                in Cambridge and elsewhere around the world.
                                            </p>
                                            <p>
                                                We value these connections, and want to maintain them, even if it’s just
                                                a monthly phone call or email.
                                            </p>
                                            <p>
                                                I hope you find Friendly Reminder as useful as I have to stay connected
                                                with friends and family.
                                            </p>
                                        </blockquote>
                                        <p className="source">
                                            Clinton Blackburn
                                            <br />
                                            <span className="title">Creator</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default LandingPageView;
