import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../context/auth';

const LogoutFooter: React.FC = () => {
    const { logout, user } = useAuth();

    return (
        user && (
            <div>
                <span role="img" aria-label="waving-hand">
                    👋🏾
                </span>{' '}
                Howdy, {user.first_name}! &bull; <Link to="/me">Edit Profile</Link> &bull;{' '}
                <Link to="/logout" onClick={logout}>
                    Logout
                </Link>
            </div>
        )
    );
};

export default LogoutFooter;
