import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCalendar,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faClock,
    faEdit,
    faEnvelope,
    faLock,
    faPhone,
    faQuoteLeft,
    faStickyNote,
    faTimes,
    faUser,
    faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

export const CSRF_TOKEN_COOKIE = 'csrftoken';
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export function resetDocumentTitle(): void {
    document.title = 'Friendly Reminder';
}

export function setDocumentTitle(title: string): void {
    document.title = `${title} | Friendly Reminder`;
}

export function setupAxios(): void {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.xsrfCookieName = CSRF_TOKEN_COOKIE;
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
}

export function setupFontAwesome(): void {
    library.add(
        faCheck,
        faCheckCircle,
        faCheckDouble,
        faClock,
        faCalendar,
        faEdit,
        faEnvelope,
        faLock,
        faPhone,
        faQuoteLeft,
        faStickyNote,
        faTimes,
        faUser,
        faUserCheck,
    );
}
