import axios from 'axios';

import {
    LoginFormData,
    PasswordChangeData,
    PasswordResetFormData,
    PasswordSetFormData,
    ProfileUpdateData,
    RegisterFormData,
    User,
} from '../interfaces';

export const PASSWORD_SET_PATH = 'set-password';
export const USER_INFO_KEY = 'userinfo';

/**
 * Get the user from the local browser cache.
 *
 * We cache the data in session storage (as opposed to local storage)
 * to ensure it is always refreshed when users return after the session
 * cookie has expired. After that time, the user will be forced to re-login
 * and we will not return invalid cached info.
 */
function getUserFromCache(): User | null {
    const raw = sessionStorage.getItem(USER_INFO_KEY);

    if (raw) {
        return JSON.parse(raw);
    }
    return null;
}

export function setUserInCache(userInfo: string): void {
    sessionStorage.setItem(USER_INFO_KEY, userInfo);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getUser({ refresh }: any): Promise<User | null> {
    const cached = refresh ? null : getUserFromCache();
    if (!cached) {
        try {
            const response = await axios.get('/api/v1/me');
            if (response.data) {
                setUserInCache(JSON.stringify(response.data));
            }
        } catch (error) {
            // NOTE: No need to do anything here. The caller should know how to
            // handle `null` values. If there is a server error, the logging on
            // the API server will catch and report it.
        }
    }

    return getUserFromCache();
}

export function clearUserInfoCache(): void {
    sessionStorage.removeItem(USER_INFO_KEY);
}

/**
 * Invalidate the user's session, and remove cached user info.
 */
export async function logout(): Promise<boolean> {
    await axios.get('/api/v1/logout');
    clearUserInfoCache();
    sessionStorage.clear();
    return true;
}

export function login(data: LoginFormData): Promise<Record<string, string>> {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    const { email, password, remember } = data;
    const form = new FormData();
    form.set('login', email);
    form.set('password', password);
    form.set('remember', String(remember));
    return axios.post('/api/v1/login', form, config);
}

export function register(data: RegisterFormData): Promise<Record<string, string>> {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    const { email, firstName, password, phoneNumber } = data;
    const form = new FormData();
    form.set('email', email);
    form.set('username', email);
    form.set('phone_number', phoneNumber);
    form.set('first_name', firstName);
    form.set('password1', password);
    form.set('password2', password);
    return axios.post('/api/v1/register', form, config);
}

export function updateUser(data: ProfileUpdateData): Promise<Record<string, string>> {
    return axios.put('/api/v1/me', data);
}

export async function changePassword(data: PasswordChangeData): Promise<Record<string, string>> {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const { oldPassword, newPassword } = data;
    const form = new FormData();
    form.set('oldpassword', oldPassword);
    form.set('password1', newPassword);
    form.set('password2', newPassword);
    return await axios.post('/api/v1/password/change', form, config);
}

export async function passwordReset(data: PasswordResetFormData): Promise<Record<string, string>> {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const { email } = data;
    const form = new FormData();
    form.set('email', email);
    return await axios.post('/api/v1/password/reset', form, config);
}

export async function passwordSet(data: PasswordSetFormData): Promise<Record<string, string>> {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const { token, userId } = data;

    const path = `/api/v1/password/reset/key/${userId}-${token}`;

    if (token === PASSWORD_SET_PATH) {
        const form = new FormData();
        const password = data.password as string;

        form.set('password1', password);
        form.set('password2', password);
        return await axios.post(path, form, config);
    } else {
        return await axios.get(path, config);
    }
}
