import { Moment } from 'moment';
import moment from 'moment-timezone';

export const currentTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function parseDateTime(datetime: string, timezone = 'UTC'): Moment {
    return moment.tz(datetime, timezone).tz(currentTimezone);
}

export function getDate(datetime: string, timezone = 'UTC'): string {
    return parseDateTime(datetime, timezone).format('YYYY-MM-DD');
}

export function getTime(datetime: string, timezone = 'UTC'): string {
    return parseDateTime(datetime, timezone).format('HH:mm:ss');
}
