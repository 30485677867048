import * as Sentry from '@sentry/browser';
import { AxiosResponse } from 'axios';
import React from 'react';
import { useAsync } from 'react-async';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import ApiClient, { Contact } from '../api';
import { MatchParams, SetSubmitting } from '../interfaces';
import { setDocumentTitle } from '../utils';
import { reportEvent } from '../utils/analytics';
import { getFullName } from '../utils/contacts';
import ContactDeleteButton from './ContactDeleteButton';
import ContactForm from './ContactForm';
import FullpageLoadingIndicator from './FullpageLoadingIndicator';
import MiniPageHeader from './MiniPageHeader';

type ContactDetailViewProps = RouteComponentProps<MatchParams>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function retrieveContact({ id }: any): Promise<AxiosResponse<Contact>> {
    return ApiClient.retrieveContact(id);
}

const ContactDetailView: React.FC<ContactDetailViewProps> = (props: ContactDetailViewProps) => {
    const { id } = props.match.params;
    const { data } = useAsync({ promiseFn: retrieveContact, id: id });

    function onSubmit(values: Contact, setSubmitting: SetSubmitting): void {
        ApiClient.updateContact(id, values)
            .then((): void => {
                reportEvent({
                    category: 'Friends',
                    action: 'Friend modified',
                });

                setSubmitting(false);
                props.history.push('/friends');
            })
            .catch((error): void => {
                // TODO Display an alert
                Sentry.captureException(error);
            });
    }

    function renderContact(contact: Contact): JSX.Element {
        const contactName = getFullName(contact);
        setDocumentTitle(contactName);

        return (
            <div className="app">
                <MiniPageHeader />
                <div className="container">
                    <div className="row">
                        <div className="form-box col-lg-6 col-md-8 col-12 ml-md-auto mr-md-auto">
                            <div className="form-box-inner">
                                <h2 className="title text-center">{contactName}</h2>
                                <ContactForm initialValue={contact} onSubmit={onSubmit} />
                                <div className="form-footer">
                                    <div className="lead">
                                        <ContactDeleteButton contact={contact} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return data ? renderContact(data.data) : <FullpageLoadingIndicator />;
};

export default withRouter(ContactDetailView);
