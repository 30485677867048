import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { Contact } from '../api';
import { SetSubmitting } from '../interfaces';
import { getDate, getTime } from '../utils/dates';

type Props = {
    initialValue: FormikValues;
    onSubmit: (values: Contact, setSubmitting: SetSubmitting) => void;
};

const ContactForm: React.FC<Props> = (props: Props) => {
    const { initialValue } = props;
    function cleanInitialValues(): void {
        /* eslint-disable @typescript-eslint/camelcase */
        const { next_reminder_at } = initialValue;

        // Deconstruct next_reminder_at into a day (integer) and time (string)
        if (next_reminder_at) {
            initialValue.next_reminder_day = getDate(next_reminder_at);
            initialValue.next_reminder_time = getTime(next_reminder_at);
        } else {
            initialValue.next_reminder_day = 0;
        }

        initialValue.phone_number = initialValue.phone_number || undefined;
        initialValue.reminder_frequency_in_days = initialValue.reminder_frequency_in_days || undefined;
        /* eslint-enable @typescript-eslint/camelcase */
    }

    cleanInitialValues();

    function onSubmit(values: FormikValues, { setSubmitting }: { setSubmitting: SetSubmitting }): void {
        if (values.next_reminder_time && values.next_reminder_day) {
            // Reconstruct next_reminder_at into a single date-time string
            const [hour, minute] = values.next_reminder_time.split(':');

            // eslint-disable-next-line @typescript-eslint/camelcase
            values.next_reminder_at = moment(values.next_reminder_day)
                .set({ hour, minute, second: 0, millisecond: 0 })
                .format();
        }

        const contact = Object.assign({}, values);
        delete contact.next_reminder_time;
        delete contact.next_reminder_day;

        props.onSubmit(contact as Contact, setSubmitting);
    }

    function validate(values: FormikValues): FormikErrors<FormikValues> {
        const errors: FormikErrors<FormikValues> = {};
        if (!values.first_name) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            errors.first_name = 'A first name is required';
        }

        return errors;
    }

    return (
        <Formik initialValues={initialValue} onSubmit={onSubmit} validate={validate} validateOnMount={true}>
            {({ isSubmitting, isValid }): JSX.Element => (
                <Form>
                    <div className="form-group">
                        <FontAwesomeIcon icon="user" />
                        <label className="sr-only" htmlFor="first_name">
                            First Name
                        </label>
                        <Field
                            type="text"
                            className="form-control"
                            id="first_name"
                            name="first_name"
                            placeholder="First name"
                        />
                    </div>
                    <div className="form-group">
                        <FontAwesomeIcon icon="user" />
                        <label className="sr-only" htmlFor="last_name">
                            Last Name
                        </label>
                        <Field
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="last_name"
                            placeholder="Last name"
                        />
                    </div>
                    <div className="form-group">
                        <FontAwesomeIcon icon="envelope" />
                        <label className="sr-only" htmlFor="email">
                            Email
                        </label>
                        <Field type="text" className="form-control" id="email" name="email" placeholder="Email" />
                    </div>
                    <div className="form-group">
                        <FontAwesomeIcon icon="phone" />
                        <label className="sr-only" htmlFor="phone_number">
                            Phone Number
                        </label>
                        <Field
                            type="tel"
                            className="form-control"
                            id="phone_number"
                            name="phone_number"
                            placeholder="Phone number"
                        />
                    </div>
                    <div className="form-group">
                        <FontAwesomeIcon icon="clock" style={{ zIndex: 1 }} />
                        <label className="sr-only" htmlFor="reminder_frequency_in_days">
                            Reminder Frequency
                        </label>
                        <div className="input-group">
                            <Field
                                type="number"
                                className="form-control"
                                id="reminder_frequency_in_days"
                                name="reminder_frequency_in_days"
                                placeholder="Reminder frequency"
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">days</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="next_reminder_day">When should we send the next friendly reminder?</label>
                        <div className="form-row">
                            <div className="col-12 col-sm-6">
                                <FontAwesomeIcon icon="calendar" />
                                <Field
                                    type="date"
                                    className="form-control"
                                    id="next_reminder_day"
                                    name="next_reminder_day"
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <FontAwesomeIcon icon="clock" className="mt-md-0 mt-3" />
                                <label className="sr-only" htmlFor="next_reminder_time">
                                    Reminder Time
                                </label>
                                <Field
                                    type="time"
                                    className="form-control mt-md-0 mt-3"
                                    id="next_reminder_time"
                                    name="next_reminder_time"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <FontAwesomeIcon icon="sticky-note" />
                        <label className="sr-only" htmlFor="note">
                            Note (included in the Friendly Reminder)
                        </label>
                        <Field
                            type="text"
                            className="form-control"
                            id="note"
                            name="note"
                            placeholder="Note (included in the Friendly Reminder)"
                            maxLength={100}
                        />
                    </div>
                    <Button
                        type="submit"
                        color="cta-primary"
                        style={{ marginRight: '.25rem' }}
                        disabled={isSubmitting || !isValid}
                        aria-disabled={isSubmitting || !isValid}
                        data-testid="submit"
                    >
                        Save
                    </Button>
                    <Link role="button" className="btn btn-secondary" to="/friends">
                        Cancel
                    </Link>
                </Form>
            )}
        </Formik>
    );
};

export default ContactForm;
